import React from 'react'
import UsersRespository from './repository'
import { Link } from 'react-router-dom'

class UserList extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            loading: true
        }
    }

    componentDidMount(){
        UsersRespository(this).getUsers()
    }

    onUsersLoadingError = (error) => {
        console.log(error)
    }

    render(){
        if(this.state.loading){
            return (
                <div>loading...</div>
            )
        }
        return (
            <table className="table">
                <thead className="table-dark">
                <tr>
                    <th>Username</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Created</th>
                </tr>
                </thead>
                <tbody>
                    {
                        this.state.users.map((user, key) => 
                        <tr key={key}>
                            <td><Link to={"/security/users/edit/" + user.id}>{user.username}</Link></td>
                            <td>{user.firstname} {user.lastname}</td>
                            <td>{user.status === 1? "Active": "Inactive"}</td>
                            <td>{new Date(user.createdAt).toString("dd MMM, yyyy")}</td>
                        </tr>
                        )
                    }
                </tbody>
            </table>
        )
    }
}

export default UserList