import React from 'react'
import EditArchiveWorker from './edit'
import { Link, withRouter } from 'react-router-dom'
import ArchiveForm from '../components/form/form.component'
import FormHelper from '../../../../utils/FormHelper'
import Dialog from '../../../../components/dialog/dialog.component'
import Loading from '../../../../components/loading/loading.component'

class EditArchive extends React.Component {
    
    constructor() {
        super()
        this.editArchiveWorker = EditArchiveWorker(this)
        this.state = this.editArchiveWorker.getDefaultState()
    }

    componentDidMount = () => {
        this.editArchiveWorker.loadJournals()
        this.editArchiveWorker.loadArchive()
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.editArchiveWorker.isValid() && this.editArchiveWorker.updateArchive()
    }

    onCancel = () => this.props.history.push('/archive/archives')

    render(){
        return(
            <div className="edit-archive">
                {
                    (this.state.loadingArchive || this.state.loadingJournals) && <Dialog><Loading /></Dialog>
                }
                <div className="card">
                    <Link className="btn" to="/journal/archives">Archives</Link>
                    <div className="header">
                        <h4>Edit Archive</h4>
                    </div>
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <ArchiveForm {...this.state} action="Save Archive" onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} onCancel={this.onCancel}/>
                </div>
            </div>
        )
    }
}

export default withRouter(EditArchive)