import React from 'react'
import { Link } from 'react-router-dom'
import ManuscriptForm from '../components/form/form.component'
import FormHelper from '../../../utils/FormHelper'
import CreateWorker from './create.worker'

class CreateManuscript extends React.Component {

    constructor(props){
        super(props)
        this.createWorker = CreateWorker(this)
        this.state = this.createWorker.getDefaultState()
    }

    componentDidMount(){
        this.createWorker.loadJournals()
    }

    onSubmit = e => {
        e.preventDefault()
        this.createWorker.isValid() && this.createWorker.createManuscript()
    }

    onCancel = () => {
        this.props.history.push('/manuscripts')
    }

    render(){
        return (
            <div className="create">
                <div className="card">
                    <Link className="btn" to="/maunscripts">Go Back</Link>
                    <div className="header">
                        <h4>Submit Manuscript</h4>
                    </div>
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <ManuscriptForm {...this.state} action="Continue" onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} onCancel={this.onCancel}/>
                </div>
            </div>
        )
    }
}

export default CreateManuscript