import React from 'react'
import iconArrowRight from '../../../../assets/icons/arrow-right-dark.svg'
import './collaborator.styles.scss'

const Collaborator = props => {
    return (
        <div className="record" onClick={() => props.onClick(props.id)}>
            <div>
                <h4>{props.title} {props.firstname} {props.lastname}</h4>
                <p>Email: {props.email}</p>
                <p>Created: {new Date(props.createdAt).toString("dd MMM yyyy")}</p>
            </div>
            <img alt="" src={iconArrowRight} />
        </div>
    )
}

export default Collaborator