import React from 'react'
import GroupsWorker from './groups'
import { Link, withRouter } from 'react-router-dom'
import Group from './group/group.component'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'

class Groups extends React.Component {

    constructor(){
        super()
        this.groupsWorker = GroupsWorker(this)
        this.state = this.groupsWorker.getDefaultState()
    }

    componentDidMount() {
        this.groupsWorker.loadGroups()
    }

    onDelete = id => this.setState({
        ...this.state,
        groups: this.state.groups.filter(group => group.id !== id)
    })

    render(){
        return (
            <div className="groups">
                {
                    this.state.loading && <Dialog><Loading /></Dialog>
                }
                <div className="card">
                    <Link className="btn" to="/journal/groups/create">New Group</Link>
                    <div className="header">
                        <h4>Groups</h4>
                    </div>
                    <div className="list">
                        {
                            this.state.groups.map((group, key) => <Group key={key} {...group} onDelete={this.onDelete} />)
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Groups)