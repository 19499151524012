import React from 'react'
import UserForm from '../../../../components/security/users/form/form.component'
import FormHelper from '../../../../utils/FormHelper'
import EditWorker from './edit'
import Checkbox from '../../../../components/inputs/checkbox/checkbox.component'

class UserEdit extends React.Component {

    constructor(props) {
        super(props)
        this.editWorker = EditWorker(this)
        this.state = this.editWorker.getDefaultState()
    }

    componentDidMount() {
        this.editWorker.loadRoles()
        this.editWorker.loadUser()
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.editWorker.isValid() && this.editWorker.updateUser()
    }

    onChangeRole = (name, checked) => this.editWorker.onChangeRole(name)

    onCancel = () => {
        this.props.history.push('/security/users')
    }

    render() {
        return (
            <div className="user-create">
                <div className="card">
                    <div className="header">
                        <h4>Edit User</h4>
                    </div>
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <UserForm {...this.state} action="Save User" onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} onCancel={this.onCancel}>
                        <h4>Roles</h4>
                        <div className="row-roles">
                            {
                                this.state.roles.map((role, key) => <Checkbox key={key} text={role.name} name={role.id} checked={this.state.grantedRoleIds.indexOf(role.id) > -1} onChange={this.onChangeRole} />)
                            }
                        </div>
                    </UserForm>
                </div>
            </div>
        )
    }
}

export default UserEdit