import React from 'react'
import CreateGroupWorker from './create'
import { Link, withRouter } from 'react-router-dom'
import GroupForm from '../../../../components/journal/groups/form/form.component'
import FormHelper from '../../../../utils/FormHelper'
import './create.styles.scss'

class CreateGroup extends React.Component {

    constructor(props) {
        super(props)
        this.createGroupWorker = CreateGroupWorker(this)
        this.state = this.createGroupWorker.getDefaultState()
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.createGroupWorker.isValid() && this.createGroupWorker.createGroup()
    }

    onCancel = () => this.props.history.push('/journal/groups')

    render() {
        return (
            <div className="create-group">
                <div className="card">
                    <Link className="btn" to="/journal/groups">Groups</Link>
                    <div className="header">
                        <h4>Create Group</h4>
                    </div>
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <GroupForm {...this.state} action="Add Group" onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} onCancel={this.onCancel}/>
                </div>
            </div>
        )
    }
}

export default withRouter(CreateGroup)