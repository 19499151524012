import React from 'react'
import CreateArticleTypeWorker from './create'
import { Link, withRouter } from 'react-router-dom'
import TypeForm from '../components/form/form.component'
import FormHelper from '../../../../utils/FormHelper'
import './create.styles.scss'

class CreateType extends React.Component {

    constructor() {
        super()
        this.createTypeWorker = CreateArticleTypeWorker(this)
        this.state = this.createTypeWorker.getDefaultState()
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.createTypeWorker.isValid() && this.createTypeWorker.createArticleType()
    }

    onCancel = () => this.props.history.push('/article/article-types')

    render() {
        return (
            <div className="create-type">
                <div className="card">
                    <Link className="btn" to="/article/types">Article Types</Link>
                    <div className="header">
                        <h4>Create Article Type</h4>
                    </div>
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <TypeForm {...this.state} action="Add Article Type" onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} onCancel={this.onCancel}/>
                </div>
            </div>
        )
    }
}

export default withRouter(CreateType)