import React from 'react'
import './accept.styles.scss'
import Worker from './worker'
import FormHelper from '../../../../utils/FormHelper'
import Form from './form.component'
import Loading from '../../../../components/loading/loading.component'
import Dialog from '../../../../components/dialog/dialog.component'
import { withRouter } from 'react-router-dom'

class AcceptDialog extends React.Component {

    constructor(props) {
        super(props)
        this.worker = Worker(this)
        this.state = this.worker.getDefaultState()
    }

    onSubmit = e => {
        e.preventDefault()
        this.worker.isValid() && this.worker.acceptCollaborator()
    }

    render() {
        return (
            <Dialog>
                <div className="accept card">
                    <div className="header">
                        <h4>Accept Collaborator</h4>
                    </div>
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    {this.state.creating ? <Loading /> : <Form {...this.state} onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} onCancel={this.props.onCancel} />}
                </div>
            </Dialog>
        )
    }
}

export default withRouter(AcceptDialog)