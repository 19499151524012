import axios from 'axios'
import { API_ENDPOINT } from '../../../config'
import store from '../../../redux/stores/store'

const getDefaultState = () => {
    return {
        loading: false,
        pages: [],
        filter: "",
        selectedIndex: -1,
        currentPage: 1,
        pageSize: 20,
        total: 0
    }
}

const loadPages = instance => {
    instance.setState({ ...instance.state, loading: true })

    let criteria = {}
    if (instance.state.filter.length > 0) {
        criteria = {
            title: instance.state.filter
        }
    }

    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/journal/pages",
        headers : {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            offset: (instance.state.currentPage - 1) * instance.state.pageSize,
            limit: instance.state.pageSize,
            criteria: criteria
        }
    }).then(response => handleLoadPagesResponse(instance, response))
    .catch(error => alert(error))
}

const handleLoadPagesResponse = (instance, response) => {
    if (response.status === 200 && response.data.status === 200) {
        instance.setState({
            ...instance.state,
            loading: false,
            pages: response.data.data.pages
        })
    }
    else if (response.data.status === 403) {
        instance.props.history.push('/auth/login')
    }
}

const PagesWorker = instance => {
    return {
        loadPages: () => loadPages(instance),
        getDefaultState: () => getDefaultState(instance)
    }
}

export default PagesWorker