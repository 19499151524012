import React from 'react'
import ArchivesWorker from './archives'
import { Link, withRouter } from 'react-router-dom'
import Archive from './archive/archive.component'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import Emitter, { EmitterEvents } from '../../../services/emitter'

class Archives extends React.Component {

    constructor(){
        super()
        this.archivesWorker = ArchivesWorker(this)
        this.state = this.archivesWorker.getDefaultState()
    }

    componentDidMount() {
        this.archivesWorker.loadArchives()
        Emitter.on(EmitterEvents.SEARCH_TEXT_ENTERED, filter => this.setState({
            ...this.state,
            filter: filter
        }, this.archivesWorker.loadArchives))
    }

    componentWillUnmount(){
        Emitter.off(EmitterEvents.SEARCH_TEXT_ENTERED)
    }

    onDelete = id => this.setState({
        ...this.state,
        archives: this.state.archives.filter(archive => archive.id !== id)
    })

    render(){
        return (
            <div className="archives">
                {
                    this.state.loading && <Dialog><Loading /></Dialog>
                }
                <div className="card">
                    <Link className="btn" to="/archive/archives/create">New Archive</Link>
                    <div className="header">
                        <h4>Archives</h4>
                    </div>
                    <div className="list">
                        {
                            this.state.archives.map((archive, key) => <Archive key={key} {...archive} onDelete={this.onDelete} />)
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Archives)