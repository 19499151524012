import React from 'react'
import UserForm from '../../../../components/security/users/form/form.component'
import FormHelper from '../../../../utils/FormHelper'
import CreateWorker from './create'
import Checkbox from '../../../../components/inputs/checkbox/checkbox.component'

class UserCreate extends React.Component {

    constructor(props) {
        super(props)
        this.createWorker = CreateWorker(this)
        this.state = this.createWorker.getDefaultState()
    }

    componentDidMount() {
        this.createWorker.loadRoles()
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.createWorker.isValid() && this.createWorker.createUser()
    }

    onChangeRole = (name, checked) => this.createWorker.onChangeRole(name)

    onCancel = () => {
        this.props.history.push('/security/users')
    }

    render() {
        return (
            <div className="user-create">
                <div className="card">
                    <div className="header">
                        <h4>Create User</h4>
                    </div>
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <UserForm {...this.state} action="Add User" onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} onCancel={this.onCancel}>
                        <h4>Roles</h4>
                        <div className="row-roles">
                            {
                                this.state.roles.map((role, key) => <Checkbox key={key} text={role.name} name={role.id} checked={this.state.grantedRoleIds.indexOf(role.id) > -1} onChange={this.onChangeRole} />)
                            }
                        </div>
                    </UserForm>
                </div>
            </div>
        )
    }
}

export default UserCreate