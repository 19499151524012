import React from 'react'
import ArticlesWorker from './articles'
import { Link, withRouter } from 'react-router-dom'
import Article from './article/article.component'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import Emitter, { EmitterEvents } from '../../../services/emitter'

class Articles extends React.Component {

    constructor(props) {
        super(props)
        this.articlesWorker = ArticlesWorker(this)
        this.state = this.articlesWorker.getDefaultState()
    }

    componentDidMount() {
        this.articlesWorker.loadArticles()
        Emitter.on(EmitterEvents.SEARCH_TEXT_ENTERED, filter => this.setState({
            ...this.state,
            filter: filter
        }, this.articlesWorker.loadArticles))
    }

    componentWillUnmount(){
        Emitter.off(EmitterEvents.SEARCH_TEXT_ENTERED)
    }

    onDelete = id => this.setState({
        ...this.state,
        articles: this.state.articles.filter(article => article.id !== id)
    })

    render() {
        return (
            <div className="articles">
                {
                    this.state.loading && <Dialog><Loading/></Dialog>
                }
                <div className="card">
                    <Link className="btn" to="/article/articles/create">New Article</Link>
                    <div className="header">
                        <h4>Articles</h4>
                    </div>
                    <div className="list">
                        {
                            this.state.articles.map((article, key) => <Article key={key} {...article} onDelete={this.onDelete} />)
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Articles)