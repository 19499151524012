import React from 'react'
import Worker from './detail.worker'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import AcceptDialog from '../components/accept/accept.component'
import RejectDialog from '../components/reject/reject.component'
import './detail.styles.scss'

class CollaboratorDetail extends React.Component {

    constructor(props) {
        super(props)
        this.worker = Worker(this)
        this.state = this.worker.getDefaultState()
    }

    componentDidMount() {
        this.worker.loadCollaborator()
    }

    render() {
        return (
            <div className="detail">
                {this.state.loadingCollaborator && <Dialog><Loading /></Dialog>}
                {this.state.showAcceptDialog && <AcceptDialog onCancel={() => this.worker.showAcceptDialog(false)} collaboratorId={this.state.id} />}
                {this.state.showRejectDialog && <RejectDialog onCancel={() => this.worker.showRejectDialog(false)} collaboratorId={this.state.id} />}
                <div className="card">
                    <div className="header">
                        <h4>Collaborator</h4>
                    </div>
                    <div>
                        <p>Name: <strong>{this.state.title} {this.state.firstname} {this.state.lastname}</strong></p>
                        <p>Email: {this.state.email}</p>
                        <p>Phone: {this.state.phone}</p>
                        <p>City: {this.state.city}</p>
                        <p>State: {this.state.state}</p>
                        <p>Country: {this.state.country}</p>
                        <p>Affiliations: {this.state.affiliations}</p>
                        <br />
                    </div>
                    <div className="header">
                        <h4>Biography</h4>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: this.state.bio }}></div>
                    <br />
                    <div className="header">
                        <h4>Journals</h4>
                    </div>
                    {this.state.journals.map((journal, key) => <h4 key={key}>{journal.title}</h4>)}
                    <br/>
                    {
                        this.state.status === 0 && <>
                            <span className="btn a" onClick={() => this.worker.showAcceptDialog(true)}>Accept</span><span> </span>
                            <span className="btn r" onClick={() => this.worker.showRejectDialog(true)}>Reject</span>
                        </>
                    }
                </div>
            </div>
        )
    }
}

export default CollaboratorDetail