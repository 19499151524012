import axios from 'axios'
import Validation from '../../../data/validation/validation'
import { StringValidationRule, EmailValidationRule } from '../../../data/validation/rules'
import { ScrollIntoViewById } from '../../../utils/UIHelper'
import { API_ENDPOINT } from '../../../config'

const LoginHelper = (instance) => {
    return {
        validate: () => {
            let validation = new Validation()
            let errors = instance.state.errors
            validation.addValidationRule(EmailValidationRule, instance.state.username, (error) => instance.state.errors.username = error, { allowNull: false, error: "Invalid" })
            validation.addValidationRule(StringValidationRule, instance.state.password, (error) => instance.state.errors.password = error, { min: { value: 6, error: "Invalid" }, max: { value: 40, error: "Invalid" } })

            instance.setState({
                ...instance.state,
                errors: errors
            }, () => {
                for (let error in instance.state.errors) {
                    if (instance.state.errors[error].length > 0) {
                        ScrollIntoViewById(error)
                        return
                    }
                }
            })
            return validation.validate()
        },
        login: () => {
            instance.setState({ ...instance.state, loginProcessing: true })
            axios({
                method: 'post',
                url: API_ENDPOINT + "/api/auth/login",
                data: {
                    username: instance.state.username,
                    password: instance.state.password,
                    otp: ""
                }
            }).then(response => {
                if (response.status === 200 && response.data.status === 200) {
                    instance.onLoginSuccess(response.data.data.auth)
                }
                else if (response.status === 200) {
                    instance.onLoginFailure(response.data.message)
                }
            }).catch(error => instance.onLoginFailure(error))
        }
    }
}

export default LoginHelper