import React from 'react'
import { Switch, Route } from "react-router-dom"
import ArticleTypeController from './article-types/article-type.controller'
import ArticlesController from './articles/article.controller'

const ArticleController = () => {
    return (
        <Switch>
            <Route path="/article/articles" component={ArticlesController} />
            <Route path="/article/article-types" component={ArticleTypeController} />
        </Switch>
    )
}

export default ArticleController