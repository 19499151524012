import React from 'react'
import iconSearch from '../../../../assets/icons/search.svg'
import './search.scss'
import Emitter, { EmitterEvents } from './../../../../services/emitter'

const Search = () => {
    return (
        <div className="search">
            <input name="search" onChange={onChange} onKeyDown={onKeyDown} autoComplete="off" />
            <img src={iconSearch} alt="" />
        </div>
    )
}

const onChange = e => Emitter.emit(EmitterEvents.SEARCH_TEXT_CHANGED, e.target.value)

const onKeyDown = e => e.key === 'Enter' && Emitter.emit(EmitterEvents.SEARCH_TEXT_ENTERED, e.target.value)

export default Search