import React from 'react'
import { withRouter } from 'react-router-dom'
import iconEdit from '../../../../assets/icons/edit-black.svg'
import iconDelete from '../../../../assets/icons/delete-black.svg'
import './page.styles.scss'
import Loading from '../../../../components/loading/loading.component'
import Worker from './worker'
import { API_ENDPOINT } from '../../../../config'

class Page extends React.Component {

    constructor(){
        super()
        this.worker = Worker(this)
        this.state = this.worker.getDefaultState()
    }

    onDelete = () => this.worker.deletePage()

    render(){
        let url = API_ENDPOINT + "/journal/" + this.props.journal.url.toLowerCase() + '/' + this.props.url
        return (
            <div className="page">
                <div>
                    <h4>{this.props.journal.url.toUpperCase()} - {this.props.title}</h4>
                    <a href={url} rel="noopener noreferrer" target="_blank">{url}</a>
                    <p>Created: {new Date(this.props.created).toString("dd MMM, yyyy")}</p>
                    <p>Updated: {new Date(this.props.updated).toString("dd MMM, yyyy")}</p>
                </div>
                <div>
                    <img alt="" src={iconEdit} onClick={() => this.props.history.push('/journal/pages/edit/' + this.props.id)} />
                    {
                        this.state.deleting ? <Loading /> : <img alt="" src={iconDelete} onClick={this.onDelete} />
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(Page)