import React from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import store from './redux/stores/store'
import { Provider } from 'react-redux'
import './app.styles.scss'
import DashboardPage from './pages/dashboard/dashboard.page'
import RequireLogin from './components/require-login/require-login.component'
import AuthController from './pages/auth/controller.page'
import DefaultTemplate from './templates/default/default.template'
import AuthTemplate from './templates/auth/auth.template'
import SecurityController from './pages/security/security.controller'
import AuthorController from './pages/author/author.controller'
import ArticleController from './pages/article/article.controller'
import ArchiveController from './pages/archive/archive.controller'
import JournalController from './pages/journal/journal.controller'
import ContentController from './pages/content/content.controller'
import ManuscriptController from './pages/manuscript/controller'
import CollaboratorController from './pages/collaborator/controller'

const AppController = () => {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/auth" component={() => <AuthTemplate><AuthController/></AuthTemplate>} />
          <Route path="/collaborators" component={() => <RequireLogin><DefaultTemplate><CollaboratorController/></DefaultTemplate></RequireLogin>} />
          <Route path="/manuscripts" component={() => <RequireLogin><DefaultTemplate><ManuscriptController/></DefaultTemplate></RequireLogin>} />
          <Route path="/security" component={() => <RequireLogin><DefaultTemplate><SecurityController/></DefaultTemplate></RequireLogin>} />
          <Route path="/author" component={() => <RequireLogin><DefaultTemplate><AuthorController/></DefaultTemplate></RequireLogin>} />
          <Route path="/article" component={() => <RequireLogin><DefaultTemplate><ArticleController/></DefaultTemplate></RequireLogin>} />
          <Route path="/archive" component={() => <RequireLogin><DefaultTemplate><ArchiveController/></DefaultTemplate></RequireLogin>} />
          <Route path="/journal" component={() => <RequireLogin><DefaultTemplate><JournalController/></DefaultTemplate></RequireLogin>} />
          <Route path="/content" component={() => <RequireLogin><DefaultTemplate><ContentController/></DefaultTemplate></RequireLogin>} />
          <Route path="/" component={() => <RequireLogin><DefaultTemplate><DashboardPage/></DefaultTemplate></RequireLogin>} />
        </Switch>
      </Router>
    </Provider>
  )
}

export default AppController
