import React from 'react'
import CreateJournalWorker from './create'
import { Link, withRouter } from 'react-router-dom'
import JournalForm from '../components/form/form.component'
import FormHelper from '../../../../utils/FormHelper'
import './create.styles.scss'
import Dialog from '../../../../components/dialog/dialog.component'
import Loading from '../../../../components/loading/loading.component'

class CreateJournal extends React.Component {

    constructor() {
        super()
        this.createJournalWorker = CreateJournalWorker(this)
        this.state = this.createJournalWorker.getDefaultState()
    }

    componentDidMount(){
        this.createJournalWorker.loadGroups()
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.createJournalWorker.isValid() && this.createJournalWorker.createJournal()
    }

    onCancel = () => this.props.history.push('/journal/journals')

    render() {
        return (
            <div className="create-journal">
                {
                    this.state.loadingGroups && <Dialog><Loading /></Dialog>
                }
                <div className="card">
                    <Link className="btn" to="/journal/journals">Journals</Link>
                    <div className="header">
                        <h4>Create Journal</h4>
                    </div>
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <JournalForm {...this.state} action="Add Journal" onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} onCancel={this.onCancel}/>
                </div>
            </div>
        )
    }
}

export default withRouter(CreateJournal)