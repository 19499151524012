import React from 'react'
import JournalsWorker from './journals'
import { Link, withRouter } from 'react-router-dom'
import Journal from './journal/journal.component'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'

class Journals extends React.Component {

    constructor() {
        super()
        this.journalsWorker = JournalsWorker(this)
        this.state = this.journalsWorker.getDefaultState()
    }

    componentDidMount() {
        this.journalsWorker.loadJournals()
    }

    onDelete = id => this.setState({
        ...this.state,
        journals: this.state.journals.filter(journal => journal.id !== id)
    })

    render() {
        return (
            <div className="journals">
                {
                    this.state.loading && <Dialog><Loading /></Dialog>
                }
                <div className="card">
                    <Link className="btn" to="/journal/journals/create">New Journal</Link>
                    <div className="header">
                        <h4>Journals</h4>
                    </div>
                    <div className="list">
                        {
                            this.state.journals.map((journal, key) => <Journal key={key} {...journal}  onDelete={this.onDelete}/>)
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Journals)