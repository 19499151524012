import axios from 'axios'
import { API_ENDPOINT } from '../../../../config'
import store from '../../../../redux/stores/store'
import Validation from '../../../../data/validation/validation'
import { StringValidationRule, PhoneValidationRule, EmailValidationRule } from '../../../../data/validation/rules'

const getDefaultState = () => {
    return {
        creating: false,
        title: "",
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        affiliations: "",
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.title, (error) => errors.firstname = error, { min: { value: 2, error: "Too short" }, max: { value: 15, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.firstname, (error) => errors.firstname = error, { min: { value: 2, error: "Too short" }, max: { value: 25, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.lastname, (error) => errors.lastname = error, { min: { value: 2, error: "Too short" }, max: { value: 25, error: "Too long" } })
    validation.addValidationRule(PhoneValidationRule, instance.state.phone, (error) => errors.phone = error, { allowNull: true })
    validation.addValidationRule(EmailValidationRule, instance.state.email, (error) => errors.email = error, { allowNull: true })
    validation.addValidationRule(StringValidationRule, instance.state.affiliations, (error) => errors.affiliations = error, { min: { value: 0, error: "Too short" }, max: { value: 300, error: "Too long" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const createAuthor = (instance) => {
    instance.setState({
        ...instance.state,
        creating: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/publisher/authors/create",
        headers: {
            "Authorization": store.getState().auth.jwt
        },
        data: {
            title: instance.state.title,
            firstname: instance.state.firstname,
            lastname: instance.state.lastname,
            phone: instance.state.phone,
            email: instance.state.email,
            affiliations: instance.state.affiliations
        }
    }).then(response => handleCreateAuthorResponse(instance, response))
        .catch(error => alert(error))
}

const handleCreateAuthorResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...getDefaultState(),
                flag: {
                    type: "success",
                    text: "Author Created"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                creating: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const CreateAuthorWorker = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        createAuthor: () => createAuthor(instance)
    }
}

export default CreateAuthorWorker