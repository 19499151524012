import axios from 'axios'
import { API_ENDPOINT } from '../../../../../config'
import store from '../../../../../redux/stores/store'

const getDefaultState = instance => {
    return {
        id: instance.props.journalId,
        image: instance.props.image,
        uploading: false
    }
}

const upload = (instance, data) => {
    instance.setState({
        ...instance.state,
        uploading: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/archive/archive/upload/cover-image",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data: data
    }).then(response => handleUploadResponse(instance, response))
    .catch(error => alert(error))
}

const handleUploadResponse = (instance, response) => {
    if(response.status !== 200){
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                uploading: false,
                image: response.data.data.coverImage
            })
            break
        default:
            instance.setState({
                ...instance.state,
                uploading: false
            })
            alert(response.data.message)
    }
}

const ImageWorker = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        upload: (data) => upload(instance, data)
    }
}

export default ImageWorker