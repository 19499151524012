import React from 'react'
import Collaborators from './collaborators/collaborators.page'
import { Switch, Route } from 'react-router-dom'
import CollaboratorDetail from './detail/detail.page'

const CollaboratorController = () => {
    return (
        <div className="collaborator">
            <Switch>
                <Route path="/collaborators/:type/:id" component={CollaboratorDetail} />
                <Route path="/collaborators/:type/" component={Collaborators} />
            </Switch>
        </div>
    )
}

export default CollaboratorController