import axios from 'axios'
import { API_ENDPOINT } from '../../../config'
import store from '../../../redux/stores/store'

const getDefaultState = () => {
    return {
        loading: false,
        archives: [],
        filter: "",
        selectedIndex: -1,
        currentPage: 1,
        pageSize: 10,
        total: 0
    }
}

const loadArchives = instance => {
    instance.setState({ ...instance.state, loading: true })
    
    let criteria = {}
    let url = API_ENDPOINT + "/api/archives"
    if (instance.state.filter.length > 0) {
        criteria = {
            q: instance.state.filter
        }
        url = url + "/search"
    }

    axios({
        method: 'post',
        url: url,
        headers : {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            offset: (instance.state.currentPage - 1) * instance.state.pageSize,
            limit: instance.state.pageSize,
            criteria: criteria
        }
    }).then(response => handleLoadArchivesResponse(instance, response))
    .catch(error => alert(error))
}

const handleLoadArchivesResponse = (instance, response) => {
    if (response.status === 200 && response.data.status === 200) {
        instance.setState({
            ...instance.state,
            loading: false,
            archives: response.data.data.archives
        })
    }
    else if (response.data.status === 403) {
        instance.props.history.push('/auth/login')
    }
    else {
        instance.setState({
            ...instance.state,
            loading: false
        })
        alert(response.data.message)
    }
}

const ArchivesWorker = instance => {
    return {
        loadArchives: () => loadArchives(instance),
        getDefaultState: () => getDefaultState(instance)
    }
}

export default ArchivesWorker