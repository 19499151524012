import axios from 'axios'
import { API_ENDPOINT } from '../../../../../config'
import store from '../../../../../redux/stores/store'
import { FileValidationRule } from "../../../../../data/validation/rules"
import Validation from "../../../../../data/validation/validation"

const getDefaultState = () => {
    return {
        uploading: false,
        file: null,
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(FileValidationRule, instance.state.file, (error) => errors.file = error, { allowNull: false })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const upload = (instance) => {
    instance.setState({
        ...instance.state,
        creating: true,
        flag: {
            type: "",
            text: ""
        }
    })
    let data = new FormData()
    data.append('articleId', instance.props.match.params.id)
    data.append('pdf', instance.state.file)
    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/article/article/upload/pdf",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data: data
    }).then(response => handleUploadResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                creating: false,
                flag: {
                    type: "error",
                    text: "Unknown error"
                }
            })
        })
}

const handleUploadResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.props.onPdfUploaded(response.data.data.pdf)
            instance.props.onCancel()
            break
        default:
            instance.setState({
                ...instance.state,
                creating: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}


const Worker = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        upload: () => upload(instance)
    }
}

export default Worker