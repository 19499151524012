import axios from 'axios'
import store from '../../../redux/stores/store'
import { API_ENDPOINT } from '../../../config'

const getDefaultState = () => {
    return {
        loading: false,
        manuscripts: [],
        filter: {
            column: "",
            value: ""
        },
        selectedIndex: -1,
        currentPage: 1,
        pageSize: 20,
        total: 0
    }
}

const loadManuscripts = instance => {
    instance.setState({
        ...instance.state,
        loading: true
    })

    let criteria = {}
    if (instance.state.filter.column.length > 0 && instance.state.filter.value.length > 0) {
        criteria = {
            [instance.state.filter.column]: instance.state.filter.value
        }
    }

    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/submission/admin/manuscripts",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            offset: (instance.state.currentPage - 1) * instance.state.pageSize,
            limit: instance.state.pageSize,
            criteria: criteria
        }
    }).then(response => handleLoadManuscriptsResponse(instance, response))
    .catch(error => alert(error))
}

const handleLoadManuscriptsResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loading: false,
                manuscripts: response.data.data.manuscripts
            })
            break
        default:
            instance.setState({
                ...instance.state,
                loading: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const ManuscriptsWorker = instance => {
    return {
        getDefaultState: getDefaultState,
        loadManuscripts: () => loadManuscripts(instance)
    }
}

export default ManuscriptsWorker