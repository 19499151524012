import axios from 'axios'
import { API_ENDPOINT } from '../../../../config'
import store from '../../../../redux/stores/store'
import Validation from '../../../../data/validation/validation'
import { NumberValidationRule } from '../../../../data/validation/rules'

const getDefaultState = () => {
    return {
        updating: false,
        loadingArchive: true,
        loadingJournals: true,
        journalId: "",
        year: "",
        month: "",
        volume: "",
        issue: "",
        journals: [],
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadArchive = instance => {
    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/archive",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data : {
            id: instance.props.match.params.id
        }
    }).then(response => handleLoadArchiveResponse(instance, response))
    .catch(error => alert(error))
}

const handleLoadArchiveResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingArchive: false,
                ...response.data.data.archive,
                journalId: response.data.data.archive.journal.id,
                flag: {
                    type: "",
                    text: ""
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                updating: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const loadJournals = instance => {
    instance.setState({
        ...instance.state,
        loadingJournals: true
    })

    let criteria = {}

    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/journal/journals",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            offset: 0,
            limit: 100,
            criteria: criteria
        }
    }).then(response => handleLoadJournalsResponse(instance, response))
    .catch(error => alert(error))
}

const handleLoadJournalsResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingJournals: false,
                journals: response.data.data.journals
            })
            break
        default:
            instance.setState({
                ...instance.state,
                updating: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(NumberValidationRule, instance.state.year, (error) => errors.year = error, { min: { value: 2010, error: "Invalid" }, max: { value: new Date().getFullYear(), error: "Invalid" } })
    validation.addValidationRule(NumberValidationRule, instance.state.month, (error) => errors.month = error, { min: { value: 1, error: "Invalid" }, max: { value: 12, error: "Invalid" } })
    validation.addValidationRule(NumberValidationRule, instance.state.volume, (error) => errors.volume = error, { min: { value: 1, error: "Invalid" }, max: { value: 99999, error: "Invalid" } })
    validation.addValidationRule(NumberValidationRule, instance.state.issue, (error) => errors.issue = error, { min: { value: 1, error: "Invalid" }, max: { value: 99999, error: "Invalid" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const updateArchive = (instance) => {
    instance.setState({
        ...instance.state,
        updating: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/archive/update",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            id: instance.props.match.params.id,
            journalId: instance.state.journalId,
            year: instance.state.year,
            month: instance.state.month,
            volume: instance.state.volume,
            issue: instance.state.issue
        }
    }).then(response => handleUpdateArchiveResponse(instance, response))
        .catch(error => alert(error))
}

const handleUpdateArchiveResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                updating: false,
                flag: {
                    type: "success",
                    text: "Archive Updated"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                updating: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const EditArchiveWorker = instance => {
    return {
        getDefaultState: getDefaultState,
        loadArchive: () => loadArchive(instance),
        isValid: () => isValid(instance),
        loadJournals: () => loadJournals(instance),
        updateArchive: () => updateArchive(instance)
    }
}

export default EditArchiveWorker