import React from 'react'
import CreateManuscript from './create/create.page'
import Manuscripts from './manuscripts/manuscripts.page'
import { Switch, Route } from 'react-router-dom'
import ManuscriptDetail from './detail/detail.page'

const ManuscriptController = () => {
    return (
        <div className="manuscript">
            <Switch>
                <Route path="/manuscripts/create" component={CreateManuscript} />
                <Route path="/manuscripts/:id" component={ManuscriptDetail} />
                <Route exact path="/manuscripts" component={Manuscripts} />
            </Switch>
        </div>
    )
}

export default ManuscriptController