import React from 'react'
import iconImage from '../../../../../assets/icons/image-black.svg'
import { API_ENDPOINT } from '../../../../../config'
import './image.styles.scss'
import ImageWorker from './image.worker'

class Image extends React.Component {

    constructor(props){
        super(props)
        this.imageWorker = ImageWorker(this)
        this.state = this.imageWorker.getDefaultState()
    }

    onChange = (e) => {
        if (e.target.files.length === 0) {
            return
        }
        let data = new FormData()
        data.append('archiveId', this.state.id)
        data.append('coverImage', e.target.files[0])
        this.imageWorker.upload(data)
    }

    render() {
        if(this.state.uploading){
            return (
                <div className="image">
                    uploading...
                </div>
            )
        }
        return (
            <div className="image">
                <button>Upload</button>
                <input type="file" name="image" accept=".jpg" onChange={this.onChange}></input>
                <div style={{backgroundImage: `url(${this.state.image.length > 0 ? API_ENDPOINT + "/" + this.state.image : iconImage})`}}></div>
            </div>
        )
    }
}

export default Image