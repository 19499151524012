import React from 'react'
import { Switch, Route } from "react-router-dom"
import Archives from './archives.page'
import CreateArchive from './create/create.page'
import EditArchive from './edit/edit.page'

const ArchiveController = () => {
    return (
        <Switch>
            <Route path="/archive/archives/edit/:id" component={EditArchive} />
            <Route path="/archive/archives/create" component={CreateArchive} />
            <Route exact path="/archive/archives" component={Archives} />
        </Switch>
    )
}

export default ArchiveController