
import axios from 'axios'
import { API_ENDPOINT } from '../../../../config'
import store from '../../../../redux/stores/store'

const UsersRespository = (instance) => {
    return {
        getUsers: () => {
            instance.setState({ ...instance.state, loadingUsers: true })
            axios({
                method: 'get',
                url: API_ENDPOINT + "/api/users",
                headers : {
                    "Authorization": store.getState().auth.jwt
                }
            }).then(response => {
                if (response.status === 200 && response.data.status === 200) {
                    instance.setState({
                        ...instance.state,
                        loading: false,
                        users: response.data.data.users
                    })
                }
                else if (response.data.status === 403) {
                    instance.props.history.push('/auth/login')
                }
            }).catch(error => instance.onUsersLoadingError(error))
        }
    }
}

export default UsersRespository