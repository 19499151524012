import React from 'react'
import Worker from './collaborators.worker'
import { withRouter } from 'react-router-dom'
import Collaborator from './collaborator/collaborator'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'

class Collaborators extends React.Component {

    constructor(props) {
        super(props)
        this.worker = Worker(this)
        this.state = this.worker.getDefaultState()
    }

    componentDidMount() {
        this.worker.loadCollaborators()
    }

    onCollaboratorClick = id => {
        this.props.history.push('/collaborators/' + this.props.match.params.type + "/" + id)
    }

    render() {
        return (
            <div className="collaborators">
                <div className="card">
                    {
                        this.state.loading && <Dialog><Loading /></Dialog>
                    }
                    <div className="header">
                        <h4>Collaborators</h4>
                    </div>
                    <div className="records">
                        {
                            this.state.collaborators.map((collaborator, key) => <Collaborator key={key} {...collaborator} onClick={this.onCollaboratorClick} />)
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Collaborators)