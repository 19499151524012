import React from 'react'
import Worker from './worker'
import Loading from '../../../../../../components/loading/loading.component'
import iconDelete from '../../../../../../assets/icons/delete-black.svg'
import { withRouter } from 'react-router-dom'

class Author extends React.Component {

    constructor(){
        super()
        this.worker = Worker(this)
        this.state = this.worker.getDefaultState()
    }

    render() {
        return (
            <div className="author">
                <div>
                    <h4>{this.props.title} {this.props.firstname} {this.props.otherNames}</h4>
                    <p>Email: {this.props.email}</p>
                    <p>Affiliations: {this.props.affiliations}</p>
                </div>
                {this.state.processing?<div><Loading/></div>:<div><img src={iconDelete} alt="" onClick={() => this.worker.removeAuthor(this.props.id)}/></div>}
            </div>
        )
    }
}

export default withRouter(Author)