import axios from 'axios'
import { API_ENDPOINT } from '../../../../config'
import store from '../../../../redux/stores/store'
import { StringValidationRule } from "../../../../data/validation/rules"
import Validation from "../../../../data/validation/validation"

const getDefaultState = () => {
    return {
        processing: false,
        message: "",
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.message, (error) => errors.message = error, { min: { value: 1, error: "Invalid" }, max: { value: 1000, error: "Invalid" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const acceptCollaborator = (instance) => {
    instance.setState({
        ...instance.state,
        processing: true,
        flag: {
            type: "",
            text: ""
        }
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/collaborators/accept",
        headers: {
            "Authorization": store.getState().auth.jwt
        },
        data: {
            collaboratorId: instance.props.collaboratorId,
            message: instance.state.message
        }
    }).then(response => handleAcceptManuscriptResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                processing: false,
                flag: {
                    type: "error",
                    text: "Unknown error"
                }
            })
        })
}

const handleAcceptManuscriptResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.props.history.push('/collaborators/' + instance.props.match.params.type)
            break
        default:
            instance.setState({
                ...instance.state,
                processing: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}


const Worker = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        acceptCollaborator: () => acceptCollaborator(instance)
    }
}

export default Worker