import React from 'react'
import { withRouter } from 'react-router-dom'
import './journal.styles.scss'
import iconEdit from '../../../../assets/icons/edit-black.svg'
import iconDelete from '../../../../assets/icons/delete-black.svg'
import Image from './image/image.component'
import Loading from '../../../../components/loading/loading.component'
import Worker from './worker'
import { API_ENDPOINT } from '../../../../config'

class Journal extends React.Component {

    constructor(){
        super()
        this.worker = Worker(this)
        this.state = this.worker.getDefaultState()
    }

    onDelete = () => this.worker.deleteJournal()

    render() {
        let url = API_ENDPOINT + "/journal/" + this.props.url.toLowerCase()
        return (
            <div className="journal">
                <div>
                    <Image image={this.props.coverImage} journalId={this.props.id} />
                </div>
                <div>
                    <h4>{this.props.title}</h4>
                    <p>Group: {this.props.group.name}</p>
                    <p>Abbreviation: {this.props.abbreviation}</p>
                    <a href={url} rel="noopener noreferrer" target="_blank">{url}</a>
                    <p>ISSN: {this.props.issn}</p>
                    <p>DOI: {this.props.doi}</p>
                    <p>Email: {this.props.email}</p>
                    <p>Created: {new Date(this.props.created).toString("dd MMM, yyyy")}</p>
                </div>
                <div>
                    <img alt="" src={iconEdit} onClick={() => this.props.history.push('/journal/journals/edit/' + this.props.id)} />
                    {
                        this.state.deleting ? <Loading /> : <img alt="" src={iconDelete} onClick={this.onDelete} />
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(Journal)