import React from 'react'
import EditJournalWorker from './edit'
import { Link, withRouter } from 'react-router-dom'
import JournalForm from '../components/form/form.component'
import FormHelper from '../../../../utils/FormHelper'
import './edit.styles.scss'
import Dialog from '../../../../components/dialog/dialog.component'
import Loading from '../../../../components/loading/loading.component'

class EditJournal extends React.Component {

    constructor() {
        super()
        this.editJournalWorker = EditJournalWorker(this)
        this.state = this.editJournalWorker.getDefaultState()
    }

    componentDidMount(){
        this.editJournalWorker.loadGroups()
        this.editJournalWorker.loadJournal()
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.editJournalWorker.isValid() && this.editJournalWorker.updateJournal()
    }

    onCancel = () => this.props.history.push('/journal/journals')

    render() {
        return (
            <div className="edit-journal">
                {
                    (this.state.loadingGroups || this.state.loadingJournal) && <Dialog><Loading /></Dialog>
                }
                <div className="card">
                    <Link className="btn" to="/journal/journals">Journals</Link>
                    <div className="header">
                        <h4>Edit Journal</h4>
                    </div>
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <JournalForm {...this.state} action="Save Journal" onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} onCancel={this.onCancel}/>
                </div>
            </div>
        )
    }
}

export default withRouter(EditJournal)