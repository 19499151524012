import React from 'react'

const AuthorForm = (props) => {
    return (
        <form>
            <div className="inputs">
            <div className="row">
                    <div className="form-label">
                        <label>Title</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="title" value={props.title} onChange={props.onChange} />
                        <span className="error">{props.errors.title}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Firstname</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="firstname" value={props.firstname} onChange={props.onChange} />
                        <span className="error">{props.errors.firstname}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Lastname</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="lastname" value={props.lastname} onChange={props.onChange} />
                        <span className="error">{props.errors.lastname}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Phone</label>
                    </div>
                    <div className="form-input">
                        <input type="phone" name="phone" value={props.phone} onChange={props.onChange} />
                        <span className="error">{props.errors.phone}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Email</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="email" value={props.email} onChange={props.onChange} />
                        <span className="error">{props.errors.email}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Affiliations</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="affiliations" value={props.affiliations} onChange={props.onChange} />
                        <span className="error">{props.errors.affiliations}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                {
                    props.creating || props.updating ?
                        <button type="submit" onClick={props.onSubmit} disabled={true}>wait...</button> :
                        <button type="submit" onClick={props.onSubmit}>{props.action}</button>
                }
                <button type="reset" onClick={props.onCancel}>Cancel</button>
            </div>
        </form>
    )
}

export default AuthorForm