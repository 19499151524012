import React from 'react'
import Worker from './edit'
import { Link, withRouter } from 'react-router-dom'
import ArticleForm from '../components/form/form.component'
import FormHelper from '../../../../utils/FormHelper'
import Dialog from '../../../../components/dialog/dialog.component'
import Loading from '../../../../components/loading/loading.component'
import AddAuthor from '../components/add-author/add-author.component'
import Authors from '../components/authors/authors.component'
import Pdf from '../components/pdf/pdf.component'
import UploadPdf from '../components/upload-pdf/upload-pdf.component'
import './edit.styles.scss'

class EditArticle extends React.Component {

    constructor() {
        super()
        this.worker = Worker(this)
        this.state = this.worker.getDefaultState()
    }

    componentDidMount() {
        this.worker.loadArchives()
        this.worker.loadArticleTypes()
        this.worker.loadArticle()
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.worker.isValid() && this.worker.updateArticle()
    }

    addToAuthors = author => {
        let authors = this.state.authors
        authors.push(author)
        this.setState({
            ...this.state,
            authors: authors
        })
    }

    onRemoveAuthor = id => {
        let authors = this.state.authors.filter((author, key) => author.id !== id)
        this.setState({
            ...this.state,
            authors: authors
        })
    }

    onNewAuthor = () => {
        this.setState({
            ...this.state,
            showAddAuthor: true
        })
    }

    closeAddAuthor = () => {
        this.setState({
            ...this.state,
            showAddAuthor: false
        })
    }

    onUploadPDF = () => {
        this.setState({
            ...this.state,
            showUploadPdf: true
        })
    }

    closeUploadPdf = () => {
        this.setState({
            ...this.state,
            showUploadPdf: false
        })
    }

    onPdfUploaded = pdf => {
        this.setState({
            ...this.state,
            pdf: pdf
        })
    }

    onCancel = () => this.props.history.push('/article/articles')

    render() {
        return (
            <div className="edit-article">
                {(this.state.updating || this.state.loadingArticle || this.state.loadingTypes) && <Dialog><Loading /></Dialog>}
                {this.state.showAddAuthor && <Dialog><AddAuthor onAddAuthor={this.addToAuthors} onCancel={this.closeAddAuthor} /></Dialog>}
                {this.state.showUploadPdf && <Dialog><UploadPdf onPdfUploaded={this.onPdfUploaded} onCancel={this.closeUploadPdf} /></Dialog>}
                <div className="card">
                    <Link className="btn" to="/article/articles">Articles</Link>
                    <div className="header">
                        <h4>Edit Article</h4>
                    </div>
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <ArticleForm {...this.state} action="Save" onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} onCancel={this.onCancel}>
                        <div className="header">
                            <h4>Authors</h4>
                        </div>
                        <span className="btn" onClick={this.onNewAuthor}>Add Author</span>
                        <Authors authors={this.state.authors} onRemove={this.onRemoveAuthor} />
                        <div className="header">
                            <h4>PDF</h4>
                        </div>
                        <span className="btn" onClick={this.onUploadPDF}>Upload PDF</span>
                        <Pdf pdf={this.state.pdf} />
                    </ArticleForm>
                </div>
            </div>
        )
    }
}

export default withRouter(EditArticle)