import axios from 'axios'
import { API_ENDPOINT } from '../../../../config'
import store from '../../../../redux/stores/store'
import Validation from '../../../../data/validation/validation'
import { StringValidationRule, EmailValidationRule, NumberValidationRule, UrlPathValidationRule } from '../../../../data/validation/rules'

const getDefaultState = () => {
    return {
        updating: false,
        loadingGroups: false,
        loadingJournal: false,
        title: "",
        url: "",
        abbreviation: "",
        amount: "",
        email: "",
        issn: "",
        doi: "",
        groupId: "",
        coverImage: false,
        errors: {},
        groups: [],
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadGroups = instance => {
    instance.setState({
        ...instance.state,
        loadingGroups: true
    })

    let criteria = {}

    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/journal/groups",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            offset: 0,
            limit: 100,
            criteria: criteria
        }
    }).then(response => handleLoadGroupsResponse(instance, response))
        .catch(error => alert(error))
}

const handleLoadGroupsResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingGroups: false,
                groups: response.data.data.groups
            })
            break
        default:
            instance.setState({
                ...instance.state,
                updating: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const loadJournal = instance => {
    instance.setState({
        ...instance.state,
        loadingJournal: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/journal/journal",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            id: instance.props.match.params.id
        }
    }).then(response => handleLoadJournalResponse(instance, response))
        .catch(error => alert(error))
}

const handleLoadJournalResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingJournal: false,
                title: response.data.data.journal.title,
                url: response.data.data.journal.url,
                abbreviation: response.data.data.journal.abbreviation,
                amount: response.data.data.journal.amount,
                email: response.data.data.journal.email,
                issn: response.data.data.journal.issn,
                doi: response.data.data.journal.doi,
                groupId: response.data.data.journal.group.id,
                flag: {
                    type: "",
                    text: ""
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                updating: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.groupId, (error) => errors.groupId = error, { min: { value: 20, error: "Too short" }, max: { value: 45, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.title, (error) => errors.title = error, { min: { value: 2, error: "Too short" }, max: { value: 300, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.abbreviation, (error) => errors.abbreviation = error, { min: { value: 2, error: "Too short" }, max: { value: 50, error: "Too long" } })
    validation.addValidationRule(UrlPathValidationRule, instance.state.url, (error) => errors.url = error, { allowNull: false })
    validation.addValidationRule(StringValidationRule, instance.state.issn, (error) => errors.issn = error, { min: { value: 0, error: "Too short" }, max: { value: 9, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.doi, (error) => errors.doi = error, { min: { value: 0, error: "Too short" }, max: { value: 200, error: "Too long" } })
    validation.addValidationRule(NumberValidationRule, instance.state.amount, (error) => errors.amount = error, { min: { value: 0, error: "Too low" }, max: { value: 5000, error: "Too high" } })
    validation.addValidationRule(EmailValidationRule, instance.state.email, (error) => errors.email = error, { allowNull: true })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const updateJournal = (instance) => {
    instance.setState({
        ...instance.state,
        updating: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/journal/journal/update",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            id: instance.props.match.params.id,
            title: instance.state.title,
            url: instance.state.url,
            abbreviation: instance.state.abbreviation,
            groupId: instance.state.groupId,
            doi: instance.state.doi,
            issn: instance.state.issn,
            amount: instance.state.amount,
            email: instance.state.email
        }
    }).then(response => handleUpdateJournalResponse(instance, response))
        .catch(error => alert(error))
}

const handleUpdateJournalResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                updating: false,
                flag: {
                    type: "success",
                    text: "Journal Updated"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                creating: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const EditJournalWorker = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        loadGroups: () => loadGroups(instance),
        loadJournal: () => loadJournal(instance),
        updateJournal: () => updateJournal(instance)
    }
}

export default EditJournalWorker