import React from 'react'
import { Switch, Route } from "react-router-dom"
import PageController from './pages/page.controller'

const SecurityController = () => {
    return (
        <Switch>
            <Route path="/content/pages" component={PageController} />
        </Switch>
    )
}

export default SecurityController