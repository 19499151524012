import React from 'react'
import PagesWorker from './pages'
import { Link, withRouter } from 'react-router-dom'
import Page from './page/page.component'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import Emitter, { EmitterEvents } from '../../../services/emitter'

class Pages extends React.Component {

    constructor() {
        super()
        this.pagesWorker = PagesWorker(this)
        this.state = this.pagesWorker.getDefaultState()
    }

    componentDidMount() {
        this.pagesWorker.loadPages()
        Emitter.on(EmitterEvents.SEARCH_TEXT_ENTERED, filter => this.setState({
            ...this.state,
            filter: filter
        }, this.pagesWorker.loadPages))
    }

    componentWillUnmount(){
        Emitter.off(EmitterEvents.SEARCH_TEXT_ENTERED)
    }

    onDelete = id => this.setState({
        ...this.state,
        pages: this.state.pages.filter(page => page.id !== id)
    })

    render() {
        return (
            <div className="pages">
                {
                    this.state.loading && <Dialog><Loading /></Dialog>
                }
                <div className="card">
                    <Link className="btn" to="/journal/pages/create">New Page</Link>
                    <div className="header">
                        <h4>Pages</h4>
                    </div>
                    <div className="list">
                        {
                            this.state.pages.map((page, key) => <Page key={key} {...page} onDelete={this.onDelete} />)
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Pages)