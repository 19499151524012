import React from 'react'
import ArticleTypesWorker from './article-types'
import { Link, withRouter } from 'react-router-dom'
import ArticleType from './article-type/article-type.component'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'

class ArticleTypes extends React.Component {

    constructor() {
        super()
        this.articleTypesWorker = ArticleTypesWorker(this)
        this.state = this.articleTypesWorker.getDefaultState()
    }

    componentDidMount() {
        this.articleTypesWorker.loadArticleTypes()
    }

    onDelete = id => this.setState({
        ...this.state,
        articleTypes: this.state.articleTypes.filter(articleType => articleType.id !== id)
    })

    render() {
        return (
            <div className="article-types">
                {
                    this.state.loading && <Dialog><Loading /></Dialog>
                }
                <div className="card">
                    <Link className="btn" to="/article/article-types/create">New Type</Link>
                    <div className="header">
                        <h4>Article Types</h4>
                    </div>
                    <div className="list">
                        {
                            this.state.articleTypes.map((articleType, key) => <ArticleType key={key} {...articleType} onDelete={this.onDelete} />)
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ArticleTypes)