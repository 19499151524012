import React from 'react'
import { withRouter } from 'react-router-dom'
import iconEdit from '../../../../assets/icons/edit-black.svg'
import iconDelete from '../../../../assets/icons/delete-black.svg'
import './archive.styles.scss'
import Image from './image/image.component'
import Loading from '../../../../components/loading/loading.component'
import Worker from './worker'
import { API_ENDPOINT } from '../../../../config'

class Archive extends React.Component {

    constructor() {
        super()
        this.worker = Worker(this)
        this.state = this.worker.getDefaultState()
    }

    onDelete = () => this.worker.deleteArchive()

    render() {
        let url = API_ENDPOINT + "/archive/" + this.props.journal.url.toLowerCase() + "/" + this.props.year + "/" + this.props.month + "/" + this.props.volume + "/" + this.props.issue
        return (
            <div className="archive">
                <div>
                    <Image key={this.props.coverImage} image={this.props.coverImage} journalId={this.props.id} />
                </div>
                <div>
                    <h4>{this.props.journal.title}</h4>
                    <p>Group: {this.props.journal.group.name}</p>
                    <p>Year: {this.props.year}</p>
                    <p>Month: {this.props.month}</p>
                    <p>Volume: {this.props.volume}</p>
                    <p>Issue: {this.props.issue}</p>
                    <p>Created: {new Date(this.props.created).toString("dd MMM, yyyy")}</p>
                    <a href={url} rel="noopener noreferrer" target="_blank">{url}</a>
                </div>
                <div>
                    <img alt="" src={iconEdit} onClick={() => this.props.history.push('/archive/archives/edit/' + this.props.id)} />
                    {
                        this.state.deleting ? <Loading /> : <img alt="" src={iconDelete} onClick={this.onDelete} />
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(Archive)