import React from 'react'
import { Switch, Route } from "react-router-dom"
import _ArchiveController from './archives/archive.controller'

const ArchiveController = () => {
    return (
        <Switch>
            <Route path="/archive/archives" component={_ArchiveController} />
        </Switch>
    )
}

export default ArchiveController