import React from 'react'
import { Switch, Route } from "react-router-dom"
import Journals from './journals.page'
import CreateJournal from './create/create.page'
import EditJournal from './edit/edit.page'

const JournalController = () => {
    return (
        <Switch>
            <Route path="/journal/journals/edit/:id" component={EditJournal} />
            <Route path="/journal/journals/create" component={CreateJournal} />
            <Route exact path="/journal/journals" component={Journals} />
        </Switch>
    )
}

export default JournalController