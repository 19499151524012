import React from 'react'
import { Switch, Route } from "react-router-dom"
import Authors from './authors.page'
import CreateAuthor from './create/create.page'
import EditAuthor from './edit/edit.page'

const AuthorsController = () => {
    return (
        <Switch>
            <Route path="/author/authors/edit/:id" component={EditAuthor} />
            <Route path="/author/authors/create" component={CreateAuthor} />
            <Route exact path="/author/authors" component={Authors} />
        </Switch>
    )
}

export default AuthorsController