import React from 'react'
import { Switch, Route } from "react-router-dom"
import Groups from './groups.page'
import CreateGroup from './create/create.page'
import EditGroup from './edit/edit.page'

const GroupController = () => {
    return (
        <Switch>
            <Route path="/journal/groups/edit/:id" component={EditGroup} />
            <Route path="/journal/groups/create" component={CreateGroup} />
            <Route exact path="/journal/groups" component={Groups} />
        </Switch>
    )
}

export default GroupController