import React from 'react'
import './auth.styles.scss'

const AuthTemplate = (props) => {
    return (
        <div className="auth">
            <header>
                <h1><br/>Merit Research Journals - Admin</h1>
            </header>
            {props.children}
            <footer>
                <ul>
                    <li>
                        <a rel="noopener noreferrer" href="https://meritresearchjournals.org" >Main Site</a>
                    </li>
                    <li>
                        <a rel="noopener noreferrer" href="https://manuscripts.meritresearchjournals.org" target="_blank">Manuscript Submission</a>
                    </li>
                    <li>
                        <a rel="noopener noreferrer" href="https://meritresearchjournals.org/terms-conditions" target="_blank">Terms & Conditions</a>
                    </li>
                    <li>
                        <a rel="noopener noreferrer" href="https://meritresearchjournals.org/privacy-policy" target="_blank">Privacy Policy</a>
                    </li>
                </ul>
                <p>© 2023 Merit Research Journals. All rights reserved.</p>
            </footer>
        </div>
    )
}

export default AuthTemplate