import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import RolesWorker from './roles'
import './roles.styles.scss'

class Roles extends React.Component {
    constructor(props) {
        super(props)
        this.rolesWorker = RolesWorker(this)
        this.state = this.rolesWorker.getDefaultState()
    }

    componentDidMount() {
        this.rolesWorker.loadRoles()
    }

    onRolesChanged = (roles) => {
        this.setState({
            ...this.state,
            loadingRoles: false,
            roles: roles
        })
    }

    renderRoles = () => {
        if (this.state.loadingRoles) {
            return (
                <div>loading...</div>
            )
        }
        return (
            <table className="table">
                <thead className="table-dark">
                    <tr>
                        <th>Name</th>
                        <th>Created</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.roles.map((role, key) =>
                            <tr key={key} onClick={()=> this.props.history.push('/security/roles/edit/' + role.id)}>
                                <td>{role.name} - <span> {role.rolePermissions.length} permissions</span></td>
                                <td>{new Date(role.createdAt).toString("dd MMM, yyyy")}</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        )
    }

    render() {
        return (
            <div className="roles">
                <div className="card">
                    <Link className="btn" to="/security/roles/create">New Role</Link>
                    <div className="header">
                        <h4>Roles</h4>
                    </div>
                </div>
                {this.renderRoles()}
            </div>
        )
    }
}

export default withRouter(Roles)