import React from 'react'
import CreatePageWorker from './create'
import { Link, withRouter } from 'react-router-dom'
import PageForm from '../components/form/form.component'
import FormHelper from '../../../../utils/FormHelper'
import './create.styles.scss'

class CreatePage extends React.Component {

    constructor(props) {
        super(props)
        this.createPageWorker = CreatePageWorker(this)
        this.state = this.createPageWorker.getDefaultState()
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.createPageWorker.isValid() && this.createPageWorker.createPage()
    }

    onCancel = () => this.props.history.push('/content/pages')

    render() {
        return (
            <div className="create-page">
                <div className="card">
                    <Link className="btn" to="/content/pages">Pages</Link>
                    <div className="header">
                        <h4>Create Page</h4>
                    </div>
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <PageForm {...this.state} action="Add Page" onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} onCancel={this.onCancel}/>
                </div>
            </div>
        )
    }
}

export default withRouter(CreatePage)