import React from 'react'
import CKEditor from '@ckeditor/ckeditor5-react'
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'

const PageForm = (props) => {
    let typeOptions = [
        {
            label: "System",
            value: 0
        },
        {
            label: "User",
            value: 1
        }
    ]

    return (
        <form>
            <div className="inputs">
                <div className="row">
                    <div className="form-label">
                        <label>Title</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="title" value={props.title} onChange={props.onChange} />
                        <span className="error">{props.errors.title}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Url</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="url" value={props.url} onChange={props.onChange} />
                        <span className="error">{props.errors.url}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Keywords</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="keywords" value={props.keywords} onChange={props.onChange} />
                        <span className="error">{props.errors.keywords}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Type</label>
                    </div>
                    <div className="form-input">
                        <select name="type" value={props.type} onChange={props.onChange}>
                            <option value=""></option>
                            {
                                typeOptions.map((typeOption, key) => <option key={key} value={typeOption.value}>{typeOption.label}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.type}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Description</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="description" value={props.description} onChange={props.onChange} />
                        <span className="error">{props.errors.description}</span>
                    </div>
                </div>
                <div className="row editor">
                    <div className="form-label">
                        <label>Content</label>
                    </div>
                    <CKEditor
                        editor={DecoupledEditor}
                        data={props.body}
                        config={{
                            allowedContent: true,
                            filebrowserUploadUrl: '/api/admin/newsletters/upload-image',
                            filebrowserUploadMethod: 'xhr',
                            fileTools_requestHeaders: {
                                Authorization: ""
                            }
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData()
                            props.onChange({
                                target: {
                                    name: "body",
                                    value: data
                                }
                            })
                        }}
                        onInit={editor => {
                            editor.ui.getEditableElement().parentElement.insertBefore(
                                editor.ui.view.toolbar.element,
                                editor.ui.getEditableElement()
                            )
                        }}
                    />
                    <span className="error">{props.errors.body}</span>
                </div>
            </div>
            <div className="actions">
                <button type="submit" onClick={props.onSubmit}>{props.action}</button>
                <button type="reset" onClick={props.onCancel}>Cancel</button>
            </div>
        </form>
    )
}

export default PageForm