import axios from 'axios'
import { API_ENDPOINT } from '../../../config'
import store from '../../../redux/stores/store'

const getDefaultState = () => {
    return {
        loadingCollaborator: false,
        showAcceptDialog: false,
        showRejectDialog: false,
        journals: []
    }
}

const loadCollaborator = instance => {
    instance.setState({
        ...instance.state,
        loadingCollaborator: true
    })
    axios({
        method: 'get',
        url: API_ENDPOINT + "/api/collaborators/"+ instance.props.match.params.id,
        headers: {
            "Authorization": store.getState().auth.jwt
        }
    }).then(response => handleLoadCollaboratorResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loadingCollaborator: false
            })
            alert(error)
        })
}

const handleLoadCollaboratorResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                ...response.data.data.collaborator,
                loadingCollaborator: false
            })
            break
        default:
            instance.setState({
                ...instance.state,
                loadingCollaborator: false
            })
            alert(response.data.message)
    }
}

const showAcceptDialog = (instance, shouldShow) => {
    instance.setState({
        ...instance.state,
        showAcceptDialog: shouldShow
    })
}

const showRejectDialog = (instance, shouldShow) => {
    instance.setState({
        ...instance.state,
        showRejectDialog: shouldShow
    })
}

const Worker = instance => {
    return {
        getDefaultState: getDefaultState,
        loadCollaborator: () => loadCollaborator(instance),
        showAcceptDialog: shouldShow => showAcceptDialog(instance, shouldShow),
        showRejectDialog: shouldShow => showRejectDialog(instance, shouldShow)
    }
}

export default Worker