import React from 'react'

const Author = props => {
    return (
        <div className="author">
            <div>
                <h4>{props.title} {props.firstname} {props.otherNames}</h4>
                <p>Email: {props.email}</p>
                <p>Affiliations: {props.affiliations}</p>
            </div>
            <div></div>
        </div>
    )
}

export default Author