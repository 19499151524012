import axios from 'axios'
import { API_ENDPOINT } from '../../../config'
import store from '../../../redux/stores/store'

const getDefaultState = () => {
    return {
        loading: false,
        authors: []
    }
}

const loadAuthors = instance => {
    instance.setState({ ...instance.state, loading: true })
    axios({
        method: 'get',
        url: API_ENDPOINT + "/api/publisher/authors",
        headers : {
            "Authorization": store.getState().auth.jwt
        }
    }).then(response => handleLoadAuthorsResponse(instance, response))
    .catch(error => alert(error))
}

const handleLoadAuthorsResponse = (instance, response) => {
    if (response.status === 200 && response.data.status === 200) {
        instance.setState({
            ...instance.state,
            loading: false,
            authors: response.data.data.authors
        })
    }
    else if (response.data.status === 403) {
        instance.props.history.push('/auth/login')
    }
}

const AuthorsWorker = instance => {
    return {
        loadAuthors: () => loadAuthors(instance),
        getDefaultState: () => getDefaultState(instance)
    }
}

export default AuthorsWorker