import React from 'react'
import ManuscriptsWorker from './manuscripts.worker'
import { withRouter } from 'react-router-dom'
import Manuscript from './manuscript/manuscript.component'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'

class Manuscripts extends React.Component {

    constructor(props) {
        super(props)
        this.maunscriptsWorker = ManuscriptsWorker(this)
        this.state = this.maunscriptsWorker.getDefaultState()
    }

    componentDidMount() {
        this.maunscriptsWorker.loadManuscripts()
    }

    onManuscriptClick = number => {
        this.props.history.push('/manuscripts/' + number)
    }

    render() {
        return (
            <div>
                <div className="card">
                    {
                        this.state.loading && <Dialog><Loading /></Dialog>
                    }
                    <div className="header">
                        <h4>Manuscripts</h4>
                    </div>
                    <div className="records">
                        {
                            this.state.manuscripts.map((manuscript, key) => <Manuscript key={key} {...manuscript} onClick={this.onManuscriptClick} />)
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Manuscripts)