import axios from 'axios'
import Worker from "../worker"
import Validation from '../../../data/validation/validation'
import {StringValidationRule} from '../../../data/validation/rules'
import {API_ENDPOINT} from '../../../config'
import store from '../../../redux/stores/store'

const getDefaultState = () => {
    return {
        creating: false,
        loadingJournals: false,
        title: "",
        journalId: "",
        errors: {},
        journals: [],
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.journalId, (error) => errors.journalId = error, { min: { value: 20, error: "Too short" }, max: { value: 45, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.title, (error) => errors.title = error, { min: { value: 2, error: "Too short" }, max: { value: 700, error: "Too long" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const createManuscript = (instance) => {
    instance.setState({
        ...instance.state,
        creating: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/manuscripts/create",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            title: instance.state.title,
            journalId: instance.state.journalId
        }
    }).then(response => handleCreateManuscriptResponse(instance, response))
    .catch(error => alert(error))
}

const handleCreateManuscriptResponse = (instance, response) => {
    if(response.status !== 200){
        return
    }
    switch (response.data.status) {
        case 200:
            instance.props.history.push('/manuscripts/' + response.data.data.manuscript.number)
            break
        default:
            instance.setState({
                ...instance.state,
                creating: false,
                flag: {
                    type: "error",
                text: response.data.message
                }
            })
    }
}

const CreateWorker = instance => {
    return {
        getDefaultState: getDefaultState,
        loadJournals: Worker(instance).loadJournals,
        isValid: () => isValid(instance),
        createManuscript: () => createManuscript(instance)
    }
}

export default CreateWorker