import React from 'react'
import { Switch, Route } from "react-router-dom"
import Pages from './pages.page'
import CreatePage from './create/create.page'
import EditPage from './edit/edit.page'

const PageController = () => {
    return (
        <Switch>
            <Route path="/content/pages/edit/:id" component={EditPage} />
            <Route path="/content/pages/create" component={CreatePage} />
            <Route exact path="/content/pages" component={Pages} />
        </Switch>
    )
}

export default PageController