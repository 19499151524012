import React from 'react'
import EditTypeWorker from './edit'
import { Link, withRouter } from 'react-router-dom'
import TypeForm from '../components/form/form.component'
import FormHelper from '../../../../utils/FormHelper'
import Dialog from '../../../../components/dialog/dialog.component'
import Loading from '../../../../components/loading/loading.component'

class EditType extends React.Component {
    
    constructor() {
        super()
        this.editTypeWorker = EditTypeWorker(this)
        this.state = this.editTypeWorker.getDefaultState()
    }

    componentDidMount = () => {
        this.editTypeWorker.loadType()
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.editTypeWorker.isValid() && this.editTypeWorker.updateType()
    }

    onCancel = () => this.props.history.push('/article/article-types')

    render(){
        return(
            <div className="edit-type">
                {
                    this.state.loadingType && <Dialog><Loading /></Dialog>
                }
                <div className="card">
                    <Link className="btn" to="/article/article-types">Article Types</Link>
                    <div className="header">
                        <h4>Edit Article Type</h4>
                    </div>
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <TypeForm {...this.state} action="Save Article Type" onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} onCancel={this.onCancel}/>
                </div>
            </div>
        )
    }
}

export default withRouter(EditType)