import React from 'react'
import { Switch, Route } from "react-router-dom"
import GroupController from './groups/group.controller'
import _JournalController from './journals/journal.controller'
import PageController from './pages/page.controller'

const JournalController = () => {
    return (
        <Switch>
            <Route path="/journal/pages" component={PageController} />
            <Route path="/journal/journals" component={_JournalController} />
            <Route path="/journal/groups" component={GroupController} />
        </Switch>
    )
}

export default JournalController