import React from 'react'
import { Link } from 'react-router-dom'
import Worker from './worker'
import Authors from '../components/authors/authors.component'
import Dialog from '../../../components/dialog/dialog.component'
import Files from '../components/files/files.component'
import Loading from '../../../components/loading/loading.component'
import AcceptDialog from '../components/accept/accept.component'
import RejectDialog from '../components/reject/reject.component'
import './detail.styles.scss'

class EditManuscript extends React.Component {

    constructor(props) {
        super(props)
        this.worker = Worker(this)
        this.state = this.worker.getDefaultState()
    }

    componentDidMount() {
        this.worker.loadingManuscript()
    }

    render() {
        return (
            <div className="detail">
                {this.state.loadingManuscript && <Dialog><Loading /></Dialog>}
                {this.state.showAcceptDialog && <AcceptDialog onCancel={() => this.worker.showAcceptDialog(false)} manuscriptId={this.state.id} />}
                {this.state.showRejectDialog && <RejectDialog onCancel={() => this.worker.showRejectDialog(false)} manuscriptId={this.state.id} />}
                <div className="card">
                    <Link className="btn" to="/manuscripts">Go Back</Link>
                    <div className="header">
                        <h4>Manuscript</h4>
                    </div>
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <div>
                        <p>Title: <strong>{this.state.title}</strong></p>
                        <p>Journal: <strong>{this.state.journal}</strong></p>
                        <br />
                    </div>
                    <div className="header">
                        <h4>Authors</h4>
                    </div>
                    <Authors authors={this.state.authors} />
                    <div className="header">
                        <h4>Files</h4>
                    </div>
                    <Files files={this.state.files} />
                    {
                        this.state.status < 2 && <>
                            <span className="btn a" onClick={() => this.worker.showAcceptDialog(true)}>Accept</span><span> </span>
                            <span className="btn r" onClick={() => this.worker.showRejectDialog(true)}>Reject</span>
                        </>
                    }
                </div>
            </div>
        )
    }
}

export default EditManuscript