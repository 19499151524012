import axios from 'axios'
import { API_ENDPOINT } from '../../../../config'
import store from '../../../../redux/stores/store'
import Validation from '../../../../data/validation/validation'
import { StringValidationRule } from '../../../../data/validation/rules'

const getDefaultState = () => {
    return {
        updating: false,
        name: "",
        errors: {},
        permissions: [],
        grantedPermissionIds: [],
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadPermissions = instance => {
    axios({
        method: 'get',
        url: API_ENDPOINT + "/api/permissions",
        headers: {
            "Authorization": store.getState().auth.jwt
        }
    }).then(response => {
        if (response.status === 200 && response.data.status === 200) {
            onPermissionsChanged(instance, response.data.data.permissions)
        }
    }).catch(error => alert(error))
}

const loadRole = instance => {
    axios({
        method: 'get',
        url: API_ENDPOINT + "/api/roles/" + instance.props.match.params.id,
        headers: {
            "Authorization": store.getState().auth.jwt
        }
    }).then(response => {
        if (response.status === 200 && response.data.status === 200) {
            onRoleChanged(instance, response.data.data.role)
        }
    }).catch(error => alert(error))
}

const onRoleChanged = (instance, role) => {
    instance.setState({
        ...instance.state,
        name: role.name,
        grantedPermissionIds: role.rolePermissions.map(rolePermission => rolePermission.permission.id)
    })
}

const onPermissionsChanged = (instance, permissions) => {
    instance.setState({
        ...instance.state,
        permissions: permissions
    })
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.name, (error) => errors.name = error, { min: { value: 2, error: "Too short" }, max: { value: 20, error: "Too long" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const updateRole = (instance) => {
    instance.setState({
        ...instance.state,
        updating: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/roles/update",
        headers: {
            "Authorization": store.getState().auth.jwt
        },
        data: {
            id: instance.props.match.params.id,
            name: instance.state.name,
            grantedPermissionIds: instance.state.grantedPermissionIds
        }
    }).then(response => {
        if (response.status === 200) {
            instance.setState({
                ...instance.state,
                updating: false,
                flag: getFlag(response.data)
            })
        }
    }).catch(error => alert(error))
}

const getFlag = response => {
    switch (response.status) {
        case 200:
            return {
                type: "success",
                text: "Role Updated"
            }
        default:
            return {
                type: "error",
                text: response.message
            }
    }
}

const onChangePermission = (instance, name) => {
    let grantedPermissionIds = instance.state.grantedPermissionIds
    if (instance.state.grantedPermissionIds.indexOf(name) > -1) {
        grantedPermissionIds = instance.state.grantedPermissionIds.filter(permissionId => permissionId !== name)
    }
    else {
        grantedPermissionIds.push(name)
    }
    instance.setState({
        ...instance.state,
        grantedPermissionIds: grantedPermissionIds
    })
}

const EditRoleWorker = instance => {
    return {
        getDefaultState: getDefaultState,
        loadPermissions: () => loadPermissions(instance),
        loadRole: () => loadRole(instance),
        isValid: () => isValid(instance),
        updateRole: () => updateRole(instance),
        onChangePermission: name => onChangePermission(instance, name)
    }
}

export default EditRoleWorker