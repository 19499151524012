import React from 'react'

const Form = props => {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="inputs">
                <div className="row">
                    <div className="form-label">
                        <label>PDF</label>
                    </div>
                    <div className="form-input">
                        <input type="file" name="file" accept=".pdf" onChange={e => props.onChange({
                            target: {
                                name: 'file',
                                value: e.target.files[0]
                            }
                        })} />
                        <span className="error">{props.errors.file}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="submit">Upload</button>
                <button type="reset" onClick={props.onCancel}>Cancel</button>
            </div>
        </form>
    )
}

export default Form