import React from 'react'

const ArchiveForm = (props) => {
    return (
        <form>
            <div className="inputs">
            <div className="row">
                    <div className="form-label">
                        <label>Journal</label>
                    </div>
                    <div className="form-input">
                        <select name="journalId" value={props.journalId} onChange={props.onChange}>
                            <option value=""></option>
                            {
                                props.journals.map((journal, key) => <option key={key} value={journal.id}>{journal.title}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.journalId}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Year</label>
                    </div>
                    <div className="form-input">
                        <select name="year" value={props.year} onChange={props.onChange}>
                            <option value=""></option>
                            {

                                getYears().map((year, key) => <option key={key} value={year}>{year}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.year}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Month</label>
                    </div>
                    <div className="form-input">
                        <select name="month" value={props.month} onChange={props.onChange}>
                            <option value=""></option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        <span className="error">{props.errors.month}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Volume</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="volume" value={props.volume} onChange={props.onChange} />
                        <span className="error">{props.errors.volume}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Issue</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="issue" value={props.issue} onChange={props.onChange} />
                        <span className="error">{props.errors.issue}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                {
                    props.creating || props.updating ?
                        <button type="submit" onClick={props.onSubmit} disabled={true}>wait...</button> :
                        <button type="submit" onClick={props.onSubmit}>{props.action}</button>
                }
                <button type="reset" onClick={props.onCancel}>Cancel</button>
            </div>
        </form>
    )
}

export default ArchiveForm

const getYears = () => {
    let years = []
    for (let year = new Date().getFullYear(); year > 2012; year--) {
        years.push(year)
    }

    return years
}