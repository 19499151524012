import React from 'react'
import EditAuthorWorker from './edit'
import { Link, withRouter } from 'react-router-dom'
import AuthorForm from '../components/form/form.component'
import FormHelper from '../../../../utils/FormHelper'

class EditAuthor extends React.Component {
    
    constructor(props) {
        super(props)
        this.editAuthorWorker = EditAuthorWorker(this)
        this.state = this.editAuthorWorker.getDefaultState()
    }

    componentDidMount = () => {
        this.editAuthorWorker.loadAuthor()
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.editAuthorWorker.isValid() && this.editAuthorWorker.updateAuthor()
    }

    onCancel = () => this.props.history.push('/author/authors')

    render(){
        return(
            <div className="edit-author">
                <div className="card">
                    <Link className="btn" to="/author/authors">Authors</Link>
                    <div className="header">
                        <h4>Edit Author</h4>
                    </div>
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <AuthorForm {...this.state} action="Save Author" onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} onCancel={this.onCancel}/>
                </div>
            </div>
        )
    }
}

export default withRouter(EditAuthor)