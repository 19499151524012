import React from 'react'
import { withRouter } from 'react-router-dom'
import iconEdit from '../../../../assets/icons/edit-black.svg'
import iconDelete from '../../../../assets/icons/delete-black.svg'
import './group.styles.scss'
import Loading from '../../../../components/loading/loading.component'
import Worker from './worker'

class Group extends React.Component {

    constructor(){
        super()
        this.worker = Worker(this)
        this.state = this.worker.getDefaultState()
    }

    onDelete = () => this.worker.deleteGroup()

    render(){
        return (
            <div className="group">
                <div>
                    <h4>{this.props.name}</h4>
                    <p>Created: {new Date(this.props.created).toString("dd MMM, yyyy")}</p>
                    <p>Updated: {new Date(this.props.updated).toString("dd MMM, yyyy")}</p>
                </div>
                <div>
                    <img alt="" src={iconEdit} onClick={() => this.props.history.push('/journal/groups/edit/' + this.props.id)} />
                    {
                        this.state.deleting ? <Loading /> : <img alt="" src={iconDelete} onClick={this.onDelete} />
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(Group)