import axios from 'axios'
import store from '../../../redux/stores/store'
import { API_ENDPOINT } from '../../../config'

const getDefaultState = instance => {
    return {
        loading: false,
        type: instance.props.match.params.type === 'editors'? 0: 1,
        status: 0,
        collaborators: [],
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadCollaborators = instance => {
    instance.setState({
        ...instance.state,
        loading: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/collaborators",
        headers: {
            "Authorization": store.getState().auth.jwt
        },
        data: {
            type: instance.state.type,
            status: instance.state.status
        }
    }).then(response => handleLoadCollaboratorsResponse(instance, response))
    .catch(error => alert(error))
}

const handleLoadCollaboratorsResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loading: false,
                collaborators: response.data.data.collaborators
            })
            break
        default:
            instance.setState({
                ...instance.state,
                loading: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const Worker = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadCollaborators: () => loadCollaborators(instance)
    }
}

export default Worker