import React from 'react'
import iconArrowRight from '../../../../assets/icons/arrow-right-dark.svg'
import './manuscript.styles.scss'

const Manuscript = props => {
    return (
        <div className="record" onClick={() => props.onClick(props.number)}>
            <div>
                <h4>{props.title}</h4>
                <p>Journal: {props.journal.title}</p>
                <p>Number: {props.number}</p>
                <p>Status: <strong>{getStatusLabel(props.status)}</strong></p>
                <p>Created: {new Date(props.created).toString("dd MMM yyyy")}</p>
            </div>
            <img alt="" src={iconArrowRight} />
        </div>
    )
}

const getStatusLabel = status => {
    switch (status) {
        case 1:
            return "Waiting for Acceptance"
        case 2:
            return "Accepted"
        case 3:
            return "Rejected"
        case 4:
            return "Published"
        default:
            return "Pending Submit"
    }
}


export default Manuscript