import axios from 'axios'
import { API_ENDPOINT } from '../../../../config'
import store from '../../../../redux/stores/store'

const getDefaultState = () => {
    return {
        deleting: false
    }
}

const deletePage = instance => {
    instance.setState({ ...instance.state, deleting: true })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/journal/page/delete",
        headers : {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            id: instance.props.id
        }
    }).then(response => handleDeletePageResponse(instance, response))
    .catch(error => alert(error))
}

const handleDeletePageResponse = (instance, response) => {
    if (response.status === 200 && response.data.status === 200) {
        instance.setState({
            ...instance.state,
            deleting: false
        })
        instance.props.onDelete(instance.props.id)
    }
    else if (response.data.status === 403) {
        instance.props.history.push('/auth/login')
    }
}

const Worker = instance => {
    return {
        deletePage: () => deletePage(instance),
        getDefaultState: () => getDefaultState(instance)
    }
}

export default Worker