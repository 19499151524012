import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import imageCoin from '../../assets/images/coin.png'
import './dashboard.styles.scss'
import Worker from './worker'
import Dialog from '../../components/dialog/dialog.component'
import Loading from '../../components/loading/loading.component'
import NumberFormat from 'react-number-format'

class DashboardPage extends React.Component {

    constructor() {
        super()
        this.worker = Worker(this)
        this.state = this.worker.getDefaultState() 
    }

    componentDidMount(){
        //this.worker.loadMetrics()
    }

    render() {
        return (
            <div className="dashboard">
                {
                    this.state.loading && <Dialog><Loading /></Dialog>
                }
                <div className="cards">
                    <div className="card payments">
                        <div>
                            <img alt="" src={imageCoin} />
                        </div>
                        <div>
                            <strong>Pending Payments</strong>
                            <span><NumberFormat value={this.state.metrics.invoices.unpaid} displayType={'text'} thousandSeparator={true} /></span>
                        </div>
                    </div>
                    <div className="card program">
                        <div>
                            <img alt="" src={imageCoin} />
                        </div>
                        <div>
                            <strong>Articles in Press</strong>
                            <span><NumberFormat value={this.state.metrics.articles.in_press} displayType={'text'} thousandSeparator={true} /></span>
                        </div>
                    </div>
                    <div className="card applications">
                        <div>
                            <img alt="" src={imageCoin} />
                        </div>
                        <div>
                            <strong>Pending Manuscripts</strong>
                            <span><NumberFormat value={this.state.metrics.manuscripts.pending} displayType={'text'} thousandSeparator={true} /></span>
                        </div>
                    </div>
                    <div className="card points">
                        <div>
                            <img alt="" src={imageCoin} />
                        </div>
                        <div>
                            <strong>Published Articles</strong>
                            <span><NumberFormat value={this.state.metrics.articles.published} displayType={'text'} thousandSeparator={true} /></span>
                        </div>
                    </div>
                    <div className="card referrals">
                        <div>
                            <img alt="" src={imageCoin} />
                        </div>
                        <div>
                            <strong>Mailing List</strong>
                            <span>0</span>
                        </div>
                    </div>
                    <div className="card membership">
                        <div>
                            <img alt="" src={imageCoin} />
                        </div>
                        <div>
                            <strong>Total Payments</strong>
                            <span><NumberFormat value={this.state.metrics.invoices.paid} displayType={'text'} thousandSeparator={true} /></span>
                        </div>
                    </div>
                    <div className="card payments">
                        <div>
                            <img alt="" src={imageCoin} />
                        </div>
                        <div>
                            <strong>Reviewers</strong>
                            <span>none</span>
                        </div>
                    </div>
                    <div className="card referrals">
                        <div>
                            <img alt="" src={imageCoin} />
                        </div>
                        <div>
                            <strong>Editors</strong>
                            <span>none</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(DashboardPage))
