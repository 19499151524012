import React from 'react'
import { Switch, Route } from "react-router-dom"
import AuthorsController from './authors/author.controller'

const AuthorController = () => {
    return (
        <Switch>
            <Route path="/author/manuscripts" component={AuthorsController} />
            <Route path="/author/authors" component={AuthorsController} />
        </Switch>
    )
}

export default AuthorController