import React from 'react'
import { Switch, Route } from "react-router-dom"
import ArticleTypes from './article-types.page'
import CreateType from './create/create.page'
import EditType from './edit/edit.page'

const ArticleTypeController = () => {
    return (
        <Switch>
            <Route path="/article/article-types/edit/:id" component={EditType} />
            <Route path="/article/article-types/create" component={CreateType} />
            <Route exact path="/article/article-types" component={ArticleTypes} />
        </Switch>
    )
}

export default ArticleTypeController