import React from 'react'
import {Link} from 'react-router-dom'

export const Item = (props) => {
    return (
        <li className={props.active ? 'item active': 'item'}>
            <span className="item"  onClick={props.onClick}>
            <img src={props.icon} alt="" /> {props.text} 
            </span>
            <ul>
                {
                    props.subItems.map(
                    (menu, key) => <li key={key}><Link onClick={props.onSubItemClick} to={menu.url}>{menu.text}</Link></li>
                    )
                }
            </ul>
        </li>
    )
}

export default Item