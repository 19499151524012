import React from 'react'
import CKEditor from '@ckeditor/ckeditor5-react'
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'

const ArticleForm = (props) => {
    return (
        <form>
            <div className="inputs">
                <div className="row">
                    <div className="form-label">
                        <label>Archive</label>
                    </div>
                    <div className="form-input">
                        <select name="archiveId" value={props.archiveId} onChange={props.onChange}>
                            <option value=""></option>
                            {
                                props.archives.map((archive, key) => <option key={key} value={archive.id}>{archive.journal.url.toUpperCase()} {archive.year}/{archive.month}/{archive.volume}/{archive.issue}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.archiveId}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Article Type</label>
                    </div>
                    <div className="form-input">
                        <select name="articleTypeId" value={props.articleTypeId} onChange={props.onChange}>
                            <option value=""></option>
                            {
                                props.articleTypes.map((articleType, key) => <option key={key} value={articleType.id}>{articleType.name}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.articleTypeId}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Title</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="title" value={props.title} onChange={props.onChange} />
                        <span className="error">{props.errors.title}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Keywords</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="keywords" value={props.keywords} onChange={props.onChange} />
                        <span className="error">{props.errors.keywords}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Description</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="description" value={props.description} onChange={props.onChange} />
                        <span className="error">{props.errors.description}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Page Start</label>
                    </div>
                    <div className="form-input">
                        <input type="number" name="pageStart" value={props.pageStart} onChange={props.onChange} />
                        <span className="error">{props.errors.pageStart}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Page End</label>
                    </div>
                    <div className="form-input">
                        <input type="number" name="pageEnd" value={props.pageEnd} onChange={props.onChange} />
                        <span className="error">{props.errors.pageEnd}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Publication Date</label>
                    </div>
                    <div className="form-input">
                        <input type="date" name="publicationDate" value={props.publicationDate} onChange={props.onChange} />
                        <span className="error">{props.errors.publicationDate}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Doi</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="doi" value={props.doi} onChange={props.onChange} />
                        <span className="error">{props.errors.doi}</span>
                    </div>
                </div>
                {
                    props.children
                }
                <div className="row editor">
                    <div className="form-label">
                        <label>Content</label>
                    </div>
                    <CKEditor
                        editor={DecoupledEditor}
                        data={props.fullText}
                        config={{
                            allowedContent: true,
                            filebrowserUploadUrl: '/api/admin/newsletters/upload-image',
                            filebrowserUploadMethod: 'xhr',
                            fileTools_requestHeaders: {
                                Authorization: ""
                            }
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData()
                            props.onChange({
                                target: {
                                    name: "fullText",
                                    value: data
                                }
                            })
                        }}
                        onInit={editor => {
                            editor.ui.getEditableElement().parentElement.insertBefore(
                                editor.ui.view.toolbar.element,
                                editor.ui.getEditableElement()
                            )
                        }}
                    />
                    <span className="error">{props.errors.fullText}</span>
                </div>
            </div>
            <div className="actions">
                <button type="submit" onClick={props.onSubmit}>{props.action}</button>
                <button type="reset" onClick={props.onCancel}>Cancel</button>
            </div>
        </form>
    )
}

export default ArticleForm