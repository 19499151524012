import React from 'react'
import CreateArchiveWorker from './create'
import { Link, withRouter } from 'react-router-dom'
import ArchiveForm from '../components/form/form.component'
import FormHelper from '../../../../utils/FormHelper'
import './create.styles.scss'
import Dialog from '../../../../components/dialog/dialog.component'
import Loading from '../../../../components/loading/loading.component'

class CreateArchive extends React.Component {

    constructor(props) {
        super(props)
        this.createArchiveWorker = CreateArchiveWorker(this)
        this.state = this.createArchiveWorker.getDefaultState()
    }

    componentDidMount(){
        this.createArchiveWorker.loadJournals()
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.createArchiveWorker.isValid() && this.createArchiveWorker.createArchive()
    }

    onCancel = () => this.props.history.push('/archive/archives')

    render() {
        return (
            <div className="create-archive">
                {
                    this.state.loadingJournals && <Dialog><Loading /></Dialog>
                }
                <div className="card">
                    <Link className="btn" to="/archive/archives">Archives</Link>
                    <div className="header">
                        <h4>Create Archive</h4>
                    </div>
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <ArchiveForm {...this.state} action="Add Archive" onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} onCancel={this.onCancel}/>
                </div>
            </div>
        )
    }
}

export default withRouter(CreateArchive)