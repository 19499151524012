import React from 'react'
import AuthorsWorker from './authors'
import { Link, withRouter } from 'react-router-dom'

class Authors extends React.Component {

    constructor(props){
        super(props)
        this.authorsWorker = AuthorsWorker(this)
        this.state = this.authorsWorker.getDefaultState()
    }

    componentDidMount() {
        this.authorsWorker.loadAuthors()
    }

    renderAuthors = () => {
        if (this.state.loading) {
            return (
                <div>loading...</div>
            )
        }
        return (
            <table className="table">
                <thead className="table-dark">
                    <tr>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Affiliations</th>
                        <th>Created</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.authors.map((author, key) =>
                            <tr key={key} onClick={()=> this.props.history.push('/author/authors/edit/' + author.id)}>
                                <td>{author.title} {author.firstname} {author.lastname}</td>
                                <td>{author.phone}</td>
                                <td>{author.email}</td>
                                <td>{author.affiliations}</td>
                                <td>{new Date(author.createdAt).toString("dd MMM, yyyy")}</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        )
    }

    render(){
        return (
            <div className="authors">
                <div className="card">
                    <Link className="btn" to="/author/authors/create">New Author</Link>
                    <div className="header">
                        <h4>Authors</h4>
                    </div>
                </div>
                {this.renderAuthors()}
            </div>
        )
    }
}

export default withRouter(Authors)