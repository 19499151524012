import axios from 'axios'
import { API_ENDPOINT } from '../../config'
import store from '../../redux/stores/store'

const getDefaultState = () => {
    return {
        loading: false,
        metrics: {
            invoices: {
                unpaid: 0,
                paid: 0
            },
            articles: {
                in_press: 0,
                published: 0
            },
            manuscripts: {
                pending: 0
            }
        }
    }
}

const loadMetrics = instance => {
    instance.setState({
        ...instance.state,
        loading: true
    })
    axios({
        method: 'get',
        url: API_ENDPOINT + "/api/metrics",
        headers: {
            "Authorization": store.getState().auth.jwt
        }
    }).then(response => handleLoadMetricsResponse(instance, response))
    .catch(error => alert(error))
}

const handleLoadMetricsResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loading: false,
                metrics: response.data.data
            })
            break
        default:
            instance.setState({
                ...instance.state,
                loading: false
            })
            alert(response.data.message)
    }
}

const Worker = instance => {
    return {
        getDefaultState: getDefaultState,
        loadMetrics: () => loadMetrics(instance)
    }
}

export default Worker