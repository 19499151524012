import axios from 'axios'
import { API_ENDPOINT } from '../../../../config'
import store from '../../../../redux/stores/store'
import Validation from '../../../../data/validation/validation'
import { StringValidationRule, PhoneValidationRule } from '../../../../data/validation/rules'

const getDefaultState = () => {
    return {
        updating: false,
        firstname: "",
        lastname: "",
        phone: "",
        roles: [],
        grantedRoleIds: [],
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadRoles = instance => {
    axios({
        method: 'get',
        url: API_ENDPOINT + "/api/roles",
        headers: {
            "Authorization": store.getState().auth.jwt
        }
    }).then(response => {
        if (response.status === 200 && response.data.status === 200) {
            onRolesChanged(instance, response.data.data.roles)
        }
    }).catch(error => alert(error))
}

const onRolesChanged = (instance, roles) => {
    instance.setState({
        ...instance.state,
        roles: roles
    })
}

const loadUser = instance => {
    axios({
        method: 'get',
        url: API_ENDPOINT + "/api/users/" + instance.props.match.params.id,
        headers: {
            "Authorization": store.getState().auth.jwt
        }
    }).then(response => {
        if (response.status === 200 && response.data.status === 200) {
            onUserChanged(instance, response.data.data.user)
        }
    }).catch(error => alert(error))
}

const onUserChanged = (instance, user) => {
    instance.setState({
        ...instance.state,
        firstname: user.firstname,
        lastname: user.lastname,
        phone: user.phone,
        grantedRoleIds: user.userRoles.map(userRole => userRole.role.id)
    })
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.firstname, (error) => errors.firstname = error, { min: { value: 2, error: "Too short" }, max: { value: 25, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.lastname, (error) => errors.lastname = error, { min: { value: 2, error: "Too short" }, max: { value: 25, error: "Too long" } })
    validation.addValidationRule(PhoneValidationRule, instance.state.phone, (error) => errors.phone = error, { allowNull: false })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const updateUser = instance => {
    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/users/update",
        headers: {
            "Authorization": store.getState().auth.jwt
        },
        data: {
            id: instance.props.match.params.id,
            username: instance.state.username,
            password: instance.state.password,
            firstname: instance.state.firstname,
            lastname: instance.state.lastname,
            phone: instance.state.phone,
            status: 1,
            grantedRoleIds: instance.state.grantedRoleIds
        }
    }).then(response => {
        if (response.status === 200) {
            instance.setState({
                ...instance.state,
                flag: getFlag(response.data)
            })
        }
    }).catch(error => alert(error))
}

const getFlag = response => {
    switch (response.status) {
        case 200:
            return {
                type: "success",
                text: "User Updated"
            }
        default:
            return {
                type: "error",
                text: response.message
            }
    }
}

const onChangeRole = (instance, name) => {
    let grantedRoleIds = instance.state.grantedRoleIds
    if (instance.state.grantedRoleIds.indexOf(name) > -1) {
        grantedRoleIds = instance.state.grantedRoleIds.filter(RoleId => RoleId !== name)
    }
    else {
        grantedRoleIds.push(name)
    }
    instance.setState({
        ...instance.state,
        grantedRoleIds: grantedRoleIds
    })
}

const EditWorker = instance => {
    return {
        loadRoles: () => loadRoles(instance),
        loadUser: () => loadUser(instance),
        isValid: () => isValid(instance),
        updateUser: () => updateUser(instance),
        getDefaultState: () => getDefaultState(),
        onChangeRole: name => onChangeRole(instance, name)
    }
}

export default EditWorker