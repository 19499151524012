import React from 'react'
import { Link } from 'react-router-dom'
import UserList from '../../../components/security/users/list/list.component'

const Users = () => {
    return (
        <div className="users">
            <div className="card">
                <div className="header">
                    <h4>Users</h4>
                </div>
                <Link className="btn" to="/security/users/create">New User</Link>
            </div>
            <UserList />
        </div>
    )
}

export default Users