import axios from 'axios'
import { API_ENDPOINT } from '../../../config'
import store from '../../../redux/stores/store'

const getDefaultState = () => {
    return {
        loading: false,
        articleTypes: [],
        filter: {
            column: "",
            value: ""
        },
        selectedIndex: -1,
        currentPage: 1,
        pageSize: 20,
        total: 0
    }
}

const loadArticleTypes = instance => {
    instance.setState({ ...instance.state, loading: true })

    let criteria = {}
    if (instance.state.filter.column.length > 0 && instance.state.filter.value.length > 0) {
        criteria = {
            [instance.state.filter.column]: instance.state.filter.value
        }
    }

    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/article/article-types",
        headers : {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            offset: (instance.state.currentPage - 1) * instance.state.pageSize,
            limit: instance.state.pageSize,
            criteria: criteria
        }
    }).then(response => handleLoadArticleTypesResponse(instance, response))
    .catch(error => alert(error))
}

const handleLoadArticleTypesResponse = (instance, response) => {
    if (response.status === 200 && response.data.status === 200) {
        instance.setState({
            ...instance.state,
            loading: false,
            articleTypes: response.data.data.articleTypes
        })
    }
    else if (response.data.status === 403) {
        instance.props.history.push('/auth/login')
    }
}

const ArticleTypesWorker = instance => {
    return {
        loadArticleTypes: () => loadArticleTypes(instance),
        getDefaultState: () => getDefaultState(instance)
    }
}

export default ArticleTypesWorker