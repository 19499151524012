import axios from 'axios'
import { API_ENDPOINT } from '../../../config'
import store from '../../../redux/stores/store'

const getDefaultState = () => {
    return {
        loadingRoles: false,
        roles: []
    }
}

const loadRoles = instance => {
    instance.setState({ ...instance.state, loadingRoles: true })
    axios({
        method: 'get',
        url: API_ENDPOINT + "/api/roles",
        headers : {
            "Authorization": store.getState().auth.jwt
        }
    }).then(response => {
        if (response.status === 200 && response.data.status === 200) {
            instance.onRolesChanged(response.data.data.roles)
        }
        else if (response.data.status === 403) {
            instance.props.history.push('/auth/login')
        }
    }).catch(error => instance.onUsersLoadingError(error))
}


const RolesWorker = instance => {
    return {
        loadRoles: () => loadRoles(instance),
        getDefaultState: () => getDefaultState(instance)
    }
}

export default RolesWorker