import axios from 'axios'
import { API_ENDPOINT } from '../../../../config'
import store from '../../../../redux/stores/store'
import Validation from '../../../../data/validation/validation'
import { NumberValidationRule } from '../../../../data/validation/rules'

const getDefaultState = () => {
    return {
        creating: false,
        loadingJournals: false,
        journalId: "",
        year: "",
        month: "",
        volume: "",
        issue: "",
        journals: [],
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadJournals = instance => {
    instance.setState({
        ...instance.state,
        loadingJournals: true
    })

    let criteria = {}

    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/journal/journals",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            offset: 0,
            limit: 100,
            criteria: criteria
        }
    }).then(response => handleLoadJournalsResponse(instance, response))
    .catch(error => alert(error))
}

const handleLoadJournalsResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingJournals: false,
                journals: response.data.data.journals
            })
            break
        default:
            instance.setState({
                ...instance.state,
                updating: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(NumberValidationRule, instance.state.year, (error) => errors.year = error, { min: { value: 2010, error: "Invalid" }, max: { value: new Date().getFullYear(), error: "Invalid" } })
    validation.addValidationRule(NumberValidationRule, instance.state.month, (error) => errors.month = error, { min: { value: 1, error: "Invalid" }, max: { value: 12, error: "Invalid" } })
    validation.addValidationRule(NumberValidationRule, instance.state.volume, (error) => errors.volume = error, { min: { value: 1, error: "Invalid" }, max: { value: 99999, error: "Invalid" } })
    validation.addValidationRule(NumberValidationRule, instance.state.issue, (error) => errors.issue = error, { min: { value: 1, error: "Invalid" }, max: { value: 99999, error: "Invalid" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const createArchive = (instance) => {
    instance.setState({
        ...instance.state,
        creating: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/archive/create",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            journalId: instance.state.journalId,
            year: instance.state.year,
            month: instance.state.month,
            volume: instance.state.volume,
            issue: instance.state.issue
        }
    }).then(response => handleCreateArchiveResponse(instance, response))
    .catch(error => alert(error))
}

const handleCreateArchiveResponse = (instance, response) => {
    if(response.status !== 200){
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...getDefaultState(),
                flag: {
                    type: "success",
                    text: "Archive Created"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                creating: false,
                flag: {
                    type: "error",
                text: response.data.message
                }
            })
    }
}

const CreateArchiveWorker = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        loadJournals: () => loadJournals(instance),
        createArchive: () => createArchive(instance)
    }
}

export default CreateArchiveWorker