import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import LoginForm from './form/form.page'
import FormHelper from '../../../utils/FormHelper'
import '../../../assets/styles/form.scss'
import './login.styles.scss'
import LoginHelper from './helper'

class AuthLogin extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            loginProcessing: false,
            message: "Enter Username and Password",
            username: "",
            password: "",
            rememberMe: "",
            errors: {}
        }
    }

    onSubmit = (e) => {
        e.preventDefault()
        let helper = LoginHelper(this)
        helper.validate() && helper.login()
    }

    onLoginSuccess = (user) => {
        sessionStorage.setItem("auth", JSON.stringify(user))
        this.props.history.push('/')
    }

    onLoginFailure = (message) => {
        this.setState({
            loginProcessing: false,
            message: message
        })
    }

    render(){
        return (
            <div className="login">
                <h1><span>Sign in</span> <span>or <b onClick={() => this.props.history.push("/auth/reset")}>reset password</b></span></h1>
                <h4>{this.state.message}</h4>
                <LoginForm onSubmit={this.onSubmit} onChange={FormHelper(this).onChange} {...this.state}/>
            </div>
        )
    }
}

export default connect(null)(withRouter(AuthLogin))