import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import './profile.scss'
import iconArrowDownDark from '../../../../assets/icons/arrow-down-dark.svg'
import imageAvatar from '../../../../assets/images/avatar.png'

const Profile = (props) => {
    return (
        <div className="profile">
            <div>
                <div className="img-cont">
                    <img src={imageAvatar} alt="" />
                </div>
                <strong>{props.auth.firstname} {props.auth.lastname}</strong>
                <img className="arrow-down" src={iconArrowDownDark} alt="" />
                <div className="clearfix"></div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Profile))